import { createAsyncThunk } from '@reduxjs/toolkit';
import { patchUpdateDefectItemAssignments } from 'scenes/DefectList/services/patch-update-defect-item-assignments.service';
import { selectSelectedProjectId } from 'store/projects/selectors/projectsSelectors';
import {
  selectDefectAssignmentModifications,
  selectSelectedDefectItemIdToReassign,
} from '../selectors/defect-list.selectors';

export const reassignDefectItem = createAsyncThunk(
  'defectLists/reassignDefectItem',
  async (_, { getState }) => {
    const selectedProjectId = selectSelectedProjectId(getState());

    const defectItemId = selectSelectedDefectItemIdToReassign(getState());

    const assignmentsToAdd = selectDefectAssignmentModifications(getState());

    const response = await patchUpdateDefectItemAssignments(
      selectedProjectId,
      defectItemId,
      Object.values(assignmentsToAdd).flat()
    );

    return response;
  }
);
