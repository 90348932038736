import { createAsyncThunk } from '@reduxjs/toolkit';
import { postUpdateCustomer } from '../services/postUpdateCustomer.service';
import { selectSelectedProject } from 'store/projects/selectors/projectsSelectors';

export const updateCustomer = createAsyncThunk(
  'customer/updateCustomer',
  async (data, { getState }) => {
    const customerData = data;

    const selectedProject = selectSelectedProject(getState());
    const businessId = selectedProject.business_id;
    if (!businessId) {
      throw new Error('No business id given');
    }

    const customerUpdateData = await postUpdateCustomer(
      businessId,
      customerData
    );

    return customerUpdateData;
  }
);
