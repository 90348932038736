import { createSelector } from '@reduxjs/toolkit';
import { groupBy, orderBy, uniqBy } from 'lodash-es';
import { selectIsBusinessAdminOrOwnerOfSelectedProject } from 'scenes/Permissions/store/selectors/personalPermissionsSelectors';
import {
  selectProjectsArray,
  selectSelectedProjectId,
} from 'store/projects/selectors/projectsSelectors';
import { selectSelectedProjectResources } from 'store/resources/selectors/resources.selectors';
import { selectLoggedInUserId } from 'store/user/selectors.js/user.selectors';

const selectDefectListState = (state) => state.defectList;

export const selectDefectListsEntities = createSelector(
  [selectDefectListState],
  (state) => state.defectLists.entities
);

export const selectDefectItemsEntities = createSelector(
  [selectDefectListState],
  (state) => state.defectItems.entities
);

export const selectDefectImagesEntities = createSelector(
  [selectDefectListState],
  (state) => state.defectImages.entities
);

export const selectDefectImagesArray = createSelector(
  [selectDefectImagesEntities],
  (entities) => Object.values(entities)
);

export const selectDefectListsArray = createSelector(
  [selectDefectListsEntities],
  (entities) => Object.values(entities)
);

export const selectDefectItemsArray = createSelector(
  [selectDefectItemsEntities],
  (entities) => Object.values(entities)
);

export const selectSelectedProjectDefectLists = createSelector(
  [selectDefectListsArray, selectSelectedProjectId],
  (defectLists, selectedProjectId) =>
    defectLists.filter(
      (defectList) => defectList.projectId === selectedProjectId
    )
);

export const selectSelectedProjectDefectItems = createSelector(
  [selectDefectItemsArray, selectSelectedProjectId],
  (defectItems, selectedProjectId) =>
    defectItems.filter(
      (defectItem) => defectItem.projectId === selectedProjectId
    )
);

export const selectUsersByRecordStatusByCompany = createSelector(
  (state) => state.defectList.users,
  (users) => {
    console.log('🚀 ~ users', users);
    return users;
  }
);

export const selectDefectItemsToCreate = createSelector(
  [selectDefectListState],
  (state) => state.defectItemsToCreate
);

export const selectDefectAssignmentsEntities = createSelector(
  [selectDefectListState],
  (state) => state.defectAssignments.entities
);

export const selectDefectAssignments = createSelector(
  [selectDefectAssignmentsEntities],
  (defectAssignmentEntities) => Object.values(defectAssignmentEntities)
);

export const selectDefectAssignmentModifications = createSelector(
  [selectDefectListState],
  (state) => state.defectAssignmentModifications
);

export const selectDefectImagesToAdd = createSelector(
  [selectDefectListState],
  (state) => state.defectImagesToAdd
);

export const selectUsersCombined = createSelector(
  selectUsersByRecordStatusByCompany,
  (users) => {
    let combinedObject = {};
    Object.keys(users).forEach((key) => {
      Object.keys(users[key]).map((company) => {
        combinedObject = { ...combinedObject, [company]: users[key][company] };
      });
    });
    return combinedObject;
  }
);

export const selectIsDefectListsDataLoading = createSelector(
  [selectDefectListState],
  (state) => state.loading
);

export const selectIsCreateDefectListDialogOpen = createSelector(
  [selectDefectListState],
  (state) => state.isCreateDefectListDialogOpen
);

export const selectSelectedDefectListId = createSelector(
  [selectDefectListState],
  (state) => state.selectedDefectListId
);

export const selectIsEditingListAttributes = createSelector(
  [selectDefectListState],
  (state) => state.isEditingListAttributes
);

export const selectDefectListAttributeModifications = createSelector(
  [selectDefectListState],
  (state) => state.defectListAttributeModifications
);

export const selectIsAddNewDefectsDialogOpen = createSelector(
  [selectDefectListState],
  (state) => state.isAddNewDefectsDialogOpen
);

export const selectIsCreatingOrUpdatingDefectData = createSelector(
  [selectDefectListState],
  (state) => state.isCreatingOrUpdatingDefectData
);

export const selectSelectedDefectItemIdToDelete = createSelector(
  [selectDefectListState],
  (state) => state.selectedDefectItemIdToDelete
);

export const selectSelectedDefectItemIdToEdit = createSelector(
  [selectDefectListState],
  (state) => state.selectedDefectItemIdToEdit
);

export const selectDefectEditAssignmentsToAdd = createSelector(
  [selectDefectListState],
  (state) => state.defectEditAssignmentsToAdd
);

export const selectDefectEditRemovedAssignations = createSelector(
  [selectDefectListState],
  (state) => state.defectEditRemovedAssignations
);

export const selectEditedDefectAttributes = createSelector(
  [selectDefectListState],
  (state) => state.editedDefectAttributes
);

export const selectDefectEditImagesToDelete = createSelector(
  [selectDefectListState],
  (state) => state.defectEditImagesToDelete
);

export const selectSelectedDefectItemIdToFix = createSelector(
  [selectDefectListState],
  (state) => state.selectedDefectItemIdToFix
);

export const selectSelectedAdditionDefectItemId = createSelector(
  [selectDefectListState],
  (state) => state.selectedAdditionDefectItemId
);

export const selectDefectFixAttributes = createSelector(
  [selectDefectListState],
  (state) => state.defectFixAttributes
);

export const selectDefectAdditionAttributes = createSelector(
  [selectDefectListState],
  (state) => state.defectAdditionAttributes
);

export const selectDefectFixesEntities = createSelector(
  [selectDefectListState],
  (state) => state.defectFixes.entities
);

export const selectImageDescriptions = createSelector(
  [selectDefectListState],
  (state) => state.imageDescriptions
);

export const selectLatestWorkedAtByResource = createSelector(
  [selectDefectListState],
  (state) => state.latestWorkedAtByResource
);

const selectDefectsChangeHistoryItemsByItemId = createSelector(
  [selectDefectListState],
  (state) => state.changeHistoryItemsByItemId
);

export const selectSelectedDefectItemIdToReassign = createSelector(
  [selectDefectListState],
  (state) => state.selectedDefectItemIdToReassign
);

export const selectSelectedDefectItemToReassign = createSelector(
  [selectDefectItemsEntities, selectSelectedDefectItemIdToReassign],
  (defectItems, defectId) => defectItems[defectId]
);

export const selectHistoryItemsByDefectItemId = createSelector(
  [selectDefectsChangeHistoryItemsByItemId, (_, args) => args],
  (historyItems, args) => historyItems[args]
);

export const selectDefectIdsAssignedToLoggedInUser = createSelector(
  [selectDefectAssignments, selectLoggedInUserId],
  (defectAssignments, loggedInUserId) =>
    defectAssignments
      .filter((defectAssignment) => defectAssignment.userId === loggedInUserId)
      .map((defectAssignment) => defectAssignment.defectId)
);

export const selectSelectedDefectList = createSelector(
  [selectDefectListsEntities, selectSelectedDefectListId],
  (defectListEntities, selectedDefectListId) =>
    defectListEntities[selectedDefectListId]
);

const selectSelectedDefectListDefectItems = createSelector(
  [selectDefectItemsArray, selectSelectedDefectListId],
  (defectItems, selectedDefectListId) =>
    defectItems.filter(
      (defectItem) => defectItem.defectListId === selectedDefectListId
    )
);

const selectSelectedDefectListDefectAssignments = createSelector(
  [selectDefectAssignments, selectSelectedDefectListId],
  (defectAssignments, selectedDefectListId) =>
    defectAssignments.filter(
      (defectAssignment) =>
        defectAssignment.defectListId === selectedDefectListId
    )
);

export const selectSelectedDefectListWithModifications = createSelector(
  [selectSelectedDefectList, selectDefectListAttributeModifications],
  (defectList, defectListModifications) => ({
    ...defectList,
    ...defectListModifications,
  })
);

export const selectSelectedDefectListData = createSelector(
  [
    selectSelectedDefectListDefectItems,
    selectSelectedDefectListDefectAssignments,
    selectDefectIdsAssignedToLoggedInUser,
    selectIsBusinessAdminOrOwnerOfSelectedProject,
  ],
  (
    listDefectItems,
    defectAssignments,
    defectIdsAssignedToLoggedInUser,
    isUserOwnerOfProject
  ) => {
    const userAssignedInProgressDefectItems = [];
    const userAssignedFixedDefects = [];

    const otherInProgressDefectItems = [];
    const otherFixedDefectItems = [];

    const notAssignedDefectItems = [];
    const assignedDefectItems = [];
    const reassignedDefectItems = [];
    const completedDefectItems = [];

    if (isUserOwnerOfProject) {
      listDefectItems.forEach((defectItem) => {
        if (defectItem.status === 'COMPLETED') {
          completedDefectItems.push(defectItem);
        } else {
          if (
            defectAssignments.some(
              (defectAssignment) => defectAssignment.defectId === defectItem.id
            )
          ) {
            if (defectItem.reassignCount) {
              reassignedDefectItems.push(defectItem);
            } else {
              assignedDefectItems.push(defectItem);
            }
          } else {
            notAssignedDefectItems.push(defectItem);
          }
        }
      });
    } else {
      listDefectItems.forEach((defectItem) => {
        if (defectIdsAssignedToLoggedInUser.includes(defectItem.id)) {
          if (defectItem.status === 'COMPLETED') {
            userAssignedFixedDefects.push(defectItem);
          } else {
            userAssignedInProgressDefectItems.push(defectItem);
          }
        } else {
          if (defectItem.status === 'COMPLETED') {
            otherFixedDefectItems.push(defectItem);
          } else {
            otherInProgressDefectItems.push(defectItem);
          }
        }
      });
    }

    const createdAtSortedOtherAssignedInProgressDefectItems = orderBy(
      otherInProgressDefectItems,
      'createdAt',
      'desc'
    );

    const notReassignedCreatedAtSortedInProgressDefectItems = orderBy(
      assignedDefectItems,
      'createdAt',
      'desc'
    );

    const reassignCountSortedOtherAssignedInProgressDefectItems = orderBy(
      reassignedDefectItems,
      'reassignCount',
      'desc'
    );

    const createdAtSortedOtherAssignedFixedDefectItems = orderBy(
      otherFixedDefectItems,
      'createdAt',
      'desc'
    );
    const createdAtSortedUserAssignedInProgressDefectItems = orderBy(
      userAssignedInProgressDefectItems,
      'createdAt',
      'desc'
    );

    const createdAtSortedUserAssignedFixedDefectItems = orderBy(
      userAssignedFixedDefects,
      'createdAt',
      'desc'
    );

    const workerSortedDefectItems = [
      ...createdAtSortedOtherAssignedInProgressDefectItems,
      ...createdAtSortedOtherAssignedFixedDefectItems,
    ];

    const projectOwnerSortedDefectitems = [
      ...notAssignedDefectItems,
      ...reassignCountSortedOtherAssignedInProgressDefectItems,
      ...notReassignedCreatedAtSortedInProgressDefectItems,
      ...completedDefectItems,
    ];

    const returnedSortedDefectItems = isUserOwnerOfProject
      ? projectOwnerSortedDefectitems
      : workerSortedDefectItems;

    return {
      defectItems: returnedSortedDefectItems,
      userAssignedDefectItems: [
        ...createdAtSortedUserAssignedInProgressDefectItems,
        ...createdAtSortedUserAssignedFixedDefectItems,
      ],
    };
  }
);

export const selectDefectsSummaryData = createSelector(
  [selectSelectedProjectDefectLists, selectSelectedProjectDefectItems],
  (defectLists, defectItems) => {
    const defectListsAmount = defectLists.length;
    const defectItemsAmount = defectItems.length;

    const fixedDefectItems = [];
    const inProgressDefectItems = [];

    defectItems.forEach((defectItem) => {
      if (defectItem.status === 'COMPLETED') {
        fixedDefectItems.push(defectItem);
      } else {
        inProgressDefectItems.push(defectItem);
      }
    });

    const totalFixedDefects = fixedDefectItems.length;
    const totalInProgressDefects = inProgressDefectItems.length;

    return {
      defectListsAmount,
      defectItemsAmount,
      totalFixedDefects,
      totalInProgressDefects,
    };
  }
);

export const selectDefectsAssigneeEmployerAndEmployeeOptions = createSelector(
  [
    selectSelectedProjectResources,
    selectProjectsArray,
    selectLatestWorkedAtByResource,
    (_, args) => args,
  ],
  (resources, projects, latestWorkedAtByResource, assigneeSearchText) => {
    const resourcesWithLatestWorkedAt = resources.map((resource) => ({
      ...resource,
      latestWorkedAt: latestWorkedAtByResource[resource.id]
        ? latestWorkedAtByResource[resource.id]
        : undefined,
    }));

    const latesWorkedAtSortedResources = orderBy(
      resourcesWithLatestWorkedAt,
      'latestWorkedAt',
      'asc'
    );

    const employerBusinessIds = uniqBy(
      resources.map((resource) => resource.employerBusinessId)
    );

    let employerProjects = [];

    employerBusinessIds.forEach((businessId) => {
      const foundEmployer = projects.find(
        (project) =>
          project.businessTypeEnum === 'EMPLOYER' &&
          project.business_id === businessId
      );

      if (foundEmployer) {
        if (
          (assigneeSearchText &&
            foundEmployer.name.toLowerCase().includes(assigneeSearchText)) ||
          !assigneeSearchText
        ) {
          employerProjects.push(foundEmployer);
        }
      }
    });

    const uniqueUserResourcesOfEmployer = uniqBy(
      latesWorkedAtSortedResources,
      (resource) => [resource.user, resource.employer, resource.project].join()
    );

    let resourcesThatHaveWorked = [];

    let resourcesThatHaveNotWorked = [];

    uniqueUserResourcesOfEmployer.forEach((resource) => {
      if (resource.latestWorkedAt) {
        resourcesThatHaveWorked.push(resource);
      } else {
        resourcesThatHaveNotWorked.push(resource);
      }
    });

    const latestWorkedAtOrderedResources = orderBy(
      resourcesThatHaveWorked,
      'latestWorkedAt',
      'desc'
    );

    const employeeResourcesGroupedByBusinessId = groupBy(
      [...latestWorkedAtOrderedResources, ...resourcesThatHaveNotWorked],
      'employerBusinessId'
    );

    return {
      employerOptions: employerProjects,
      employeeOptions: employeeResourcesGroupedByBusinessId,
    };
  }
);

export const selectDefectItemAssignmentsDataByDefectId = createSelector(
  [selectDefectAssignments, (_, args) => args],
  (defectAssignments, args) => {
    const thisDefectItemAssignments = defectAssignments.filter(
      (assignment) => assignment.defectId === args
    );

    let defectAssignedBusinesses = [];
    let defectUserAssignments = [];

    thisDefectItemAssignments.forEach((defectAssignment) => {
      if (
        defectAssignment.businessId &&
        !defectAssignedBusinesses.find(
          (assignmentBusiness) =>
            assignmentBusiness.businessId === defectAssignment.businessId
        )
      ) {
        defectAssignedBusinesses.push(defectAssignment);
      }

      if (defectAssignment.userId) {
        defectUserAssignments.push(defectAssignment);
      }
    });

    return {
      assignmentBusinesses: defectAssignedBusinesses,
      defectUserAssignments,
    };
  }
);

export const selectSelectedDefectItemToEdit = createSelector(
  [selectDefectItemsEntities, selectSelectedDefectItemIdToEdit],
  (defectItems, selectedDefectId) => defectItems[selectedDefectId]
);

export const selectEditDefectExistingAssignments = createSelector(
  [
    selectDefectAssignments,
    selectSelectedDefectItemIdToEdit,
    selectDefectEditAssignmentsToAdd,
    selectDefectEditRemovedAssignations,
  ],
  (
    defectAssignments,
    selectedDefectId,
    addedAssignments,
    removedAssignments
  ) => {
    const selectedDefectAssignments = defectAssignments.filter(
      (assignment) => assignment.defectId === selectedDefectId
    );

    const selectedDefectAddedAssignmentsArray = Object.values(addedAssignments);

    let removedBusinessAssignments = [];
    let removedUserAssignments = [];

    Object.values(removedAssignments).forEach((removedAssignment) => {
      if (!removedAssignment.userId) {
        removedBusinessAssignments.push(removedAssignment.businessId);
      } else {
        removedUserAssignments.push(removedAssignment.userId);
      }
    });
    const filteredExistingAssignments = selectedDefectAssignments.filter(
      (defectAssignment) => {
        const isFoundInRemovedBusinesses = removedBusinessAssignments.includes(
          defectAssignment.businessId
        );

        const isFoundInRemovedUserAssignments = removedUserAssignments.includes(
          defectAssignment.userId
        );
        if (isFoundInRemovedBusinesses || isFoundInRemovedUserAssignments) {
          return false;
        } else {
          return true;
        }
      }
    );

    return [
      ...filteredExistingAssignments,
      ...selectedDefectAddedAssignmentsArray,
    ];
  }
);

export const selectSelectedProjectDefectImages = createSelector(
  [selectDefectImagesArray, selectSelectedProjectId],
  (defectImages, selectedProjectId) =>
    defectImages.filter(
      (defectImage) => defectImage.projectId === selectedProjectId
    )
);

export const selectDefectImagesByDefectId = createSelector(
  [selectSelectedProjectDefectImages, (_, args) => args],
  (defectImages, args) => {
    const thisDefectImages = defectImages.filter(
      (defectImage) => defectImage.defectId === args
    );

    const createdAtSortedImages = orderBy(thisDefectImages, 'createdAt', 'asc');

    return createdAtSortedImages;
  }
);

export const selectSelectedDefectItemImages = createSelector(
  [
    selectDefectImagesArray,
    selectSelectedDefectItemIdToEdit,
    selectDefectEditImagesToDelete,
  ],
  (defectImages, selectedDefectId, imagesAddedForDeletion) =>
    defectImages.filter(
      (defectImage) =>
        defectImage.defectId === selectedDefectId &&
        !imagesAddedForDeletion.includes(defectImage.id)
    )
);

export const selectDefectItemsCountTotalsByDefectListId = createSelector(
  [selectSelectedProjectDefectItems, (_, args) => args],
  (defectItems, defectListId) => {
    const defectListDefectItems = defectItems.filter(
      (defectItem) => defectItem.defectListId === defectListId
    );

    const fixedDefectItems = [];
    const inProgressDefectItems = [];

    defectListDefectItems.forEach((defectItem) => {
      if (defectItem.status === 'COMPLETED') {
        fixedDefectItems.push(defectItem);
      } else {
        inProgressDefectItems.push(defectItem);
      }
    });

    const defectItemsAmount = defectListDefectItems.length;
    const totalFixedDefects = fixedDefectItems.length;
    const totalInProgressDefects = inProgressDefectItems.length;

    return {
      defectItemsAmount,
      totalFixedDefects,
      totalInProgressDefects,
    };
  }
);
