import { createAsyncThunk } from '@reduxjs/toolkit';
import getCredentials from '../../utils/getCredentials';
import getDefaultFormTemplates from 'scenes/ProjectForms/utils/getDefaultFormTemplates';

export const loadDefaultFormTemplates = createAsyncThunk(
  'projectForm/loadDefaultFormTemplates',
  async (project_id, { getState }) => {
    const { accessKeyId, secretAccessKey, sessionToken } =
      await getCredentials();

    const params = {
      accessKeyId,
      secretAccessKey,
      sessionToken,
    };

    const defaultTemplates = await getDefaultFormTemplates(project_id, params);
    return { defaultTemplates };
  }
);
