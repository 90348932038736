import { createSlice } from '@reduxjs/toolkit';
import { createEntityAdapter } from '@reduxjs/toolkit';
import { loadInitialFormsData } from './store/thunks/loadInitialFormsData';
import { loadProject } from './store/thunks/loadProject';
import { loadResourcesWithEmail } from './store/thunks/loadResourcesWithEmail';
import { loadResourcesByUser } from './store/thunks/loadResourcesByUser';
import { loadFormSendData } from './store/thunks/loadFormSendData';
import { createForm } from './store/thunks/createForm';
import { deleteForm } from './store/thunks/deleteForm';
import { editForm } from './store/thunks/editForm';
import { sendForm } from './store/thunks/sendForm';
import { setNotes } from './store/thunks/setNotes';
import { getMasterParentProject } from './store/thunks/getMasterParentProject';
import { getParentSubProjects } from './store/thunks/getParentSubProjects';
import { getFormsByBusinessId } from './store/thunks/getFormsByBusinessId';
import * as projectFormActions from './store/actions/projectFormActions';
import { getNotes } from './store/thunks/getNotes';
import { setFormStateAsync } from './store/thunks/setFormStateAsync';
import { setFormNotesState } from './store/thunks/setFormNotesState';
import { createManualResource } from './store/thunks/createManualResource';
import { loadRecordsForResources } from './store/thunks/loadRecordsForResources';
import { createFormTemplate } from './store/thunks/createFormTemplate';
import { loadFormTemplatesForProject } from './store/thunks/loadFormTemplatesForProject';
import { deleteFormTemplate } from './store/thunks/deleteFormTemplate';
import { deleteFormTemplateFromAllEmployerProjects } from './store/thunks/deleteFormTemplateFromAllEmployerProjects';
import { editFormTemplate } from './store/thunks/editFormTemplate';
import { editFormTemplateInAllEmployerProjects } from './store/thunks/editFormTemplateInAllEmployerProjects';
import { loadUsersForOrientation } from './store/thunks/loadUsersForOrientation';

import { createOrientationFormTemplate } from './store/thunks/createOrientationFormTemplate';
import { editOrientationFormTemplate } from './store/thunks/editOrientationFormTemplate';
import { loadDefaultFormTemplates } from './store/thunks/loadDefaultFormTemplates';

const formsAdapter = createEntityAdapter();
const projectAdapter = createEntityAdapter();
const subProjectsAdapter = createEntityAdapter();
const subProjectFormsAdapter = createEntityAdapter();
const masterParentAdapter = createEntityAdapter();
const resourcesAdapter = createEntityAdapter();
const formNotesAdapter = createEntityAdapter();
const manualResourcesAdapter = createEntityAdapter();
const recordsAdapter = createEntityAdapter();
const formTemplatesAdapter = createEntityAdapter();
const defaultTemplatesAdapter = createEntityAdapter();

const resetState = {
  forms: createEntityAdapter().getInitialState(),
  project: createEntityAdapter().getInitialState(),
  subProjects: createEntityAdapter().getInitialState(),
  subProjectForms: createEntityAdapter().getInitialState(),
  masterParent: createEntityAdapter().getInitialState(),
  resources: createEntityAdapter().getInitialState(),
  formNotes: createEntityAdapter().getInitialState(),
  manualResources: createEntityAdapter().getInitialState(),
  records: createEntityAdapter().getInitialState(),
  formTemplates: createEntityAdapter().getInitialState(),
  defaultTemplates: createEntityAdapter().getInitialState(),
  formState: {},
};

const projectFormSlice = createSlice({
  name: 'projectForm',
  initialState: {
    forms: formsAdapter.getInitialState(),
    project: projectAdapter.getInitialState(),
    subProjects: subProjectsAdapter.getInitialState(),
    subProjectForms: subProjectFormsAdapter.getInitialState(),
    masterParent: masterParentAdapter.getInitialState(),
    resources: resourcesAdapter.getInitialState(),
    formNotes: formNotesAdapter.getInitialState(),
    manualResources: manualResourcesAdapter.getInitialState(),
    records: recordsAdapter.getInitialState(),
    formTemplates: formTemplatesAdapter.getInitialState(),
    defaultTemplates: defaultTemplatesAdapter.getInitialState(),
    formState: {},
  },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(loadInitialFormsData.pending, (state) => ({
        ...state,
        loadingForms: true,
      }))
      .addCase(loadInitialFormsData.fulfilled, (state, action) => {
        state.loadingForms = false;
        formsAdapter.setMany(state.forms, action.payload.forms);
      })
      .addCase(loadInitialFormsData.rejected, (state, action) => ({
        ...state,
        loadingForms: false,
        error: action.error,
      }))
      .addCase(loadProject.pending, (state) => ({
        ...state,
        loadingProject: true,
      }))
      .addCase(loadProject.fulfilled, (state, action) => {
        state.loadingProject = false;
        projectAdapter.setMany(state.project, action.payload.project);
      })
      .addCase(loadProject.rejected, (state, action) => ({
        ...state,
        loadingProject: false,
        error: action.error,
      }))
      .addCase(loadUsersForOrientation.pending, (state) => ({
        ...state,
        loadingOrientationUsers: true,
      }))
      .addCase(loadUsersForOrientation.fulfilled, (state, action) => {
        state.loadingOrientationUsers = false;
        state.orientationUsers = [...action.payload.orientationUsers];
      })
      .addCase(loadUsersForOrientation.rejected, (state, action) => ({
        ...state,
        loadingOrientationUsers: false,
        error: action.error,
      }))
      .addCase(getFormsByBusinessId.pending, (state) => ({
        ...state,
        loading: true,
      }))
      .addCase(getFormsByBusinessId.fulfilled, (state, action) => {
        state.loading = false;
        subProjectFormsAdapter.setMany(state.subProjectForms, action.payload.subProjectForms);
      })
      .addCase(getFormsByBusinessId.rejected, (state, action) => ({
        ...state,
        loading: false,
        error: action.error,
      }))

      .addCase(getMasterParentProject.pending, (state) => ({
        ...state,
        loading: true,
      }))
      .addCase(getMasterParentProject.fulfilled, (state, action) => {
        state.loading = false;
        masterParentAdapter.setMany(state.masterParent, action.payload.masterParent);
      })
      .addCase(getMasterParentProject.rejected, (state, action) => ({
        ...state,
        loading: false,
        error: action.error,
      }))

      .addCase(loadResourcesWithEmail.pending, (state) => ({
        ...state,
        loading: true,
      }))
      .addCase(loadResourcesWithEmail.fulfilled, (state, action) => {
        state.loading = false;
        resourcesAdapter.setMany(state.resources, action.payload.resources);
      })
      .addCase(loadResourcesWithEmail.rejected, (state, action) => ({
        ...state,
        loading: false,
        error: action.error,
      }))

      .addCase(loadResourcesByUser.pending, (state) => ({
        ...state,
        loading: true,
      }))
      .addCase(loadResourcesByUser.fulfilled, (state, action) => {
        state.loading = false;
        resourcesAdapter.setMany(state.resources, action.payload.resources);
      })
      .addCase(loadResourcesByUser.rejected, (state, action) => ({
        ...state,
        loading: false,
        error: action.error,
      }))

      .addCase(createForm.pending, (state) => ({
        ...state,
        createFormLoading: true,
      }))
      .addCase(createForm.fulfilled, (state, action) => {
        state.createFormLoading = false;
        formsAdapter.upsertOne(
          state.forms,
          action.payload.createdForm
        );
        if (state.formState && Object.keys(state.formState).length > 0) {
          state.formState.id = action.payload.createdForm.id;
          state.formState.mirrorUser = action.payload.createdForm.mirrorUser;
        }
      })
      .addCase(createForm.rejected, (state, action) => ({
        ...state,
        createFormLoading: false,
        createFormError: action.error,
      }))

      .addCase(deleteForm.pending, (state) => ({
        ...state,
        deleteFormLoading: true,
      }))
      .addCase(deleteForm.fulfilled, (state, action) => {
        state.deleteFormLoading = false;
        formsAdapter.removeOne(
          state.forms,
          action.payload.deletedForm.id
        );
      })
      .addCase(deleteForm.rejected, (state, action) => ({
        ...state,
        deleteFormLoading: false,
        deleteFormError: action.error,
      }))

      .addCase(editForm.pending, (state) => ({
        ...state,
        editFormLoading: true,
      }))
      .addCase(editForm.fulfilled, (state, action) => {
        state.editFormLoading = false;
        formsAdapter.upsertOne(
          state.forms,
          action.payload.editedForm
        );
      })
      .addCase(editForm.rejected, (state, action) => ({
        ...state,
        editFormLoading: false,
        editFormError: action.error,
      }))

      .addCase(createOrientationFormTemplate.pending, (state) => ({
        ...state,
        createFormTemplateLoading: true,
      }))
      .addCase(createOrientationFormTemplate.fulfilled, (state, action) => {
        state.createFormTemplateLoading = false;
        formTemplatesAdapter.upsertOne(
          state.formTemplates,
          action.payload.createdFormTemplate
        );
      })
      .addCase(createOrientationFormTemplate.rejected, (state, action) => ({
        ...state,
        createFormTemplateLoading: false,
        createFormTemplateError: action.error,
      }))

      .addCase(editOrientationFormTemplate.pending, (state) => ({
        ...state,
        editFormTemplateLoading: true,
      }))
      .addCase(editOrientationFormTemplate.fulfilled, (state, action) => {
        state.editFormTemplateLoading = false;
        formTemplatesAdapter.upsertOne(
          state.formTemplates,
          action.payload.editedFormTemplate
        );
      })
      .addCase(editOrientationFormTemplate.rejected, (state, action) => ({
        ...state,
        editFormTemplateLoading: false,
        editFormTemplateError: action.error,
      }))


      .addCase(createFormTemplate.pending, (state) => ({
        ...state,
        createFormTemplateLoading: true,
      }))
      .addCase(createFormTemplate.fulfilled, (state, action) => {
        state.createFormTemplateLoading = false;
        formTemplatesAdapter.upsertOne(
          state.formTemplates,
          action.payload.createdFormTemplate
        );
      })
      .addCase(createFormTemplate.rejected, (state, action) => ({
        ...state,
        createFormTemplateLoading: false,
        createFormTemplateError: action.error,
      }))

      .addCase(editFormTemplate.pending, (state) => ({
        ...state,
        editFormTemplateLoading: true,
      }))
      .addCase(editFormTemplate.fulfilled, (state, action) => {
        state.editFormTemplateLoading = false;
        formTemplatesAdapter.upsertOne(
          state.formTemplates,
          action.payload.editedFormTemplate
        );
      })
      .addCase(editFormTemplate.rejected, (state, action) => ({
        ...state,
        editFormTemplateLoading: false,
        editFormTemplateError: action.error,
      }))

      .addCase(editFormTemplateInAllEmployerProjects.pending, (state) => ({
        ...state,
        editFormTemplateInAllEmployerProjectsLoading: true,
      }))
      .addCase(editFormTemplateInAllEmployerProjects.fulfilled, (state, action) => {
        state.editFormTemplateInAllEmployerProjectsLoading = false;
        formTemplatesAdapter.upsertOne(
          state.formTemplates,
          action.payload.editedFormTemplate
        );
      })
      .addCase(editFormTemplateInAllEmployerProjects.rejected, (state, action) => ({
        ...state,
        editFormTemplateInAllEmployerProjectsLoading: false,
        editFormTemplateInAllEmployerProjectsError: action.error,
      }))



      .addCase(deleteFormTemplate.pending, (state) => ({
        ...state,
        deleteFormTemplateLoading: true,
      }))
      .addCase(deleteFormTemplate.fulfilled, (state, action) => {
        state.deleteFormTemplateLoading = false;
        formTemplatesAdapter.removeOne(
          state.formTemplates,
          action.payload.deletedFormTemplate.id
        );
      })
      .addCase(deleteFormTemplate.rejected, (state, action) => ({
        ...state,
        deleteFormTemplateLoading: false,
        deleteFormTemplateError: action.error,
      }))

      .addCase(deleteFormTemplateFromAllEmployerProjects.pending, (state) => ({
        ...state,
        deleteFormTemplateFromAllEmployerProjectsLoading: true,
      }))
      .addCase(deleteFormTemplateFromAllEmployerProjects.fulfilled, (state, action) => {
        state.deleteFormTemplateFromAllEmployerProjectsLoading = false;
        formTemplatesAdapter.removeOne(
          state.formTemplates,
          action.payload.deletedFormTemplate.id
        );
      })
      .addCase(deleteFormTemplateFromAllEmployerProjects.rejected, (state, action) => ({
        ...state,
        deleteFormTemplateFromAllEmployerProjectsLoading: false,
        deleteFormTemplateFromAllEmployerProjectsError: action.error,
      }))

      .addCase(loadFormTemplatesForProject.pending, (state) => ({
        ...state,
        loadFormTemplatesByBusinessIdLoading: true,
      }))
      .addCase(loadFormTemplatesForProject.fulfilled, (state, action) => {
        state.loadFormTemplatesByBusinessIdLoading = false;
        formTemplatesAdapter.setMany(state.formTemplates, action.payload.formTemplates);
      })
      .addCase(loadFormTemplatesForProject.rejected, (state, action) => ({
        ...state,
        loadFormTemplatesByBusinessIdLoading: false,
        loadFormTemplatesByBusinessIdError: action.error,
      }))

      .addCase(loadDefaultFormTemplates.pending, (state) => ({
        ...state,
        loadFormTemplatesByBusinessIdLoading: true,
      }))
      .addCase(loadDefaultFormTemplates.fulfilled, (state, action) => {
        state.loadFormTemplatesByBusinessIdLoading = false;
        defaultTemplatesAdapter.setMany(state.defaultTemplates, action.payload.defaultTemplates);
      })
      .addCase(loadDefaultFormTemplates.rejected, (state, action) => ({
        ...state,
        loadFormTemplatesByBusinessIdLoading: false,
        loadDefaultFormTemplatesError: action.error,
      }))

      .addCase(setNotes.pending, (state) => ({
        ...state,
        createNotesLoading: true,
      }))
      .addCase(setNotes.fulfilled, (state, action) => {
        state.createNotesLoading = false;
        formNotesAdapter.setMany(
          state.formNotes,
          action.payload.notesSet
        );
      })
      .addCase(setNotes.rejected, (state, action) => ({
        ...state,
        createNotesLoading: false,
        createNotesError: action.error,
      }))

      .addCase(getNotes.pending, (state) => ({
        ...state,
        getNotesLoading: true,
      }))
      .addCase(getNotes.fulfilled, (state, action) => {
        state.getNotesLoading = false;
        formNotesAdapter.setMany(state.formNotes, action.payload.formNotes);
      })
      .addCase(getNotes.rejected, (state, action) => ({
        ...state,
        getNotesLoading: false,
        getNotesError: action.error,
      }))

      .addCase(sendForm.pending, (state) => ({
        ...state,
        sendFormLoading: true,
      }))
      .addCase(sendForm.fulfilled, (state) => {
        state.sendFormLoading = false;
      })
      .addCase(sendForm.rejected, (state, action) => ({
        ...state,
        sendFormLoading: false,
        sendFormError: action.error,
      }))

      .addCase(getParentSubProjects.pending, (state) => ({
        ...state,
        loading: true,
      }))
      .addCase(getParentSubProjects.fulfilled, (state, action) => {
        state.loading = false;
        subProjectsAdapter.setMany(state.subProjects, action.payload.subProjects);
      })
      .addCase(getParentSubProjects.rejected, (state, action) => ({
        ...state,
        loading: false,
        error: action.error,
      }))

      .addCase(createManualResource.pending, (state) => ({
        ...state,
        createManualResourceLoading: true,
      }))
      .addCase(createManualResource.fulfilled, (state, action) => {
        state.createManualResourceLoading = false;
        manualResourcesAdapter.addOne(
          state.manualResources,
          action.payload.createdManualResource
        );
      })
      .addCase(createManualResource.rejected, (state, action) => ({
        ...state,
        createManualResourceLoading: false,
        createManualResourceError: action.error,
      }))

      .addCase(loadFormSendData.pending, (state) => ({
        ...state,
        forms: {
          ...state.forms,
          loadingFormsSendData: true,
        },
      }))
      .addCase(loadFormSendData.fulfilled, (state, action) => {
        state.forms.loadingFormsSendData = false;
        // resourcesAdapter.setMany(state.resources, action.payload.resources);
        manualResourcesAdapter.setMany(
          state.manualResources,
          action.payload.manualResources
        );
      })
      .addCase(loadFormSendData.rejected, (state, action) => ({
        ...state,
        loadingFormsSendData: false,
        error: action.error,
      }))
      .addCase(loadRecordsForResources.pending, (state) => ({
        ...state,
        loading: true,
      }))
      .addCase(loadRecordsForResources.fulfilled, (state, action) => {
        state.loading = false;
        state.resources = action.payload.resources;
      })
      .addCase(loadRecordsForResources.rejected, (state, action) => ({
        ...state,
        loading: false,
        error: action.error,
      }))
      .addCase(projectFormActions.resetResources, (state, action) => ({
        ...state,
        resources: [],
      }))
      .addCase(projectFormActions.resetProjectForms, (state, action) => ({
        ...state,
        ...resetState,
      }))
      .addCase(projectFormActions.setFormState, (state, action) => ({
        ...state,
        formState: {
          ...state.formState,
          ...action.payload,
        },
      }))
      .addCase(setFormStateAsync.fulfilled, (state, action) => ({
        ...state,
        formStateAsyncLoading: false,
        ...action.payload,
      }))
      .addCase(projectFormActions.removeFormState, (state, action) => ({
        ...state,
        formState: {},
        unsavedChanges: undefined,
      }))
      .addCase(projectFormActions.setSnackBarState, (state, action) => ({
        ...state,
        snackBarState: {
          ...state.snackBarState,
          ...action.payload,
        },
      }
      ))
      .addCase(projectFormActions.setLoading, (state, action) => ({
        ...state,
        loading: action.payload,
      }))
      .addCase(projectFormActions.setFormNotesFilePermissions, (state, action) => ({
        ...state,
        formNotesFilePermissions: { ...action.payload }
      }))
      .addCase(projectFormActions.setCompanyLogoState, (state, action) => ({
        ...state,
        companyLogo: action.payload
      }))
      .addCase(projectFormActions.setFormLabels, (state, action) => ({
        ...state,
        formLabels: action.payload
      }))
      .addCase(projectFormActions.setOpenedItemsList, (state, action) => ({
        ...state,
        openedItemsList: action.payload
      }))
      .addCase(projectFormActions.setResourcesGroupedByEmployer, (state, action) => ({
        ...state,
        resourcesGroupedByEmployer: action.payload,
      }))
      .addCase(projectFormActions.setCurrentFormState, (state, action) => ({
        ...state,
        currentFormState: {
          ...state.currentFormState,
          ...action.payload,
        },
      }))
      .addCase(projectFormActions.setUnsavedChanges, (state, action) => ({
        ...state,
        unsavedChanges: action.payload,
      }))
      .addCase(projectFormActions.setHasChanges, (state, action) => ({
        ...state,
        hasChanges: action.payload,
      }))
      .addCase(projectFormActions.setFormNotesForSave, (state, action) => ({
        ...state,
        notesForSave: action.payload,
      }))
      .addCase(projectFormActions.setInitialTRFormNotesAsSet, (state, action) => ({
        ...state,
        initialTRFormNotesSet: action.payload,
      }))
      .addCase(projectFormActions.setWorkers, (state, action) => ({
        ...state,
        workers: action.payload,
      }))
      .addCase(projectFormActions.resetWorkers, (state, action) => ({
        ...state,
        workers: {},
      }))
      .addCase(projectFormActions.setLatestTR, (state, action) => ({
        ...state,
        latestTR: action.payload,
      }))
      .addCase(projectFormActions.resetLatestTR, (state, action) => ({
        ...state,
        latestTR: {},
      }))
      .addCase(projectFormActions.setFormTemplateChanges, (state, action) => ({
        ...state,
        formTemplateChanges: {
          ...state.formTemplateChanges,
          ...action.payload,
        },
      }))
      .addCase(projectFormActions.resetFormTemplateChanges, (state, action) => ({
        ...state,
        formTemplateChanges: {},
      }))
      .addCase(projectFormActions.setImageLoadingProgress, (state, action) => ({
        ...state,
        imageLoadingProgress: {
          progress: action.payload.progress,
          formId: action.payload.formId,
        },
      }))
      .addCase(projectFormActions.setCurrentlyLoadingFormId, (state, action) => ({
        ...state,
        currentlyLoadingFormId: action.payload,
      }))
      .addCase(projectFormActions.setIsCancelled, (state, action) => ({
        ...state,
        isCancelled: action.payload,
      }))
      .addCase(projectFormActions.setIsFormSaving, (state, action) => ({
        ...state,
        isFormSaving: action.payload,
      }))
      .addCase(projectFormActions.setUploadingProgressValues, (state, action) => ({
        ...state,
        uploadingProgressValues: action.payload,
      }))
      .addCase(projectFormActions.setHasMissingOrientationForms, (state, action) => ({
        ...state,
        hasMissingOrientationForms: action.payload,
      }))
  },
});

export const {
  selectAll: selectAllForms,
  selectEntities: selectFormEntities,
  selectById: selectFormById,
  selectIds: selectFormIds,
} = formsAdapter.getSelectors((state) => state.projectForms.forms);

export const {
  selectAll: selectAllFormTemplates,
  selectEntities: selectFormTemplateEntities,
  selectById: selectFormTemplateById,
  selectIds: selectFormTemplateIds,
} = formTemplatesAdapter.getSelectors((state) => state.projectForms.formTemplates);

export const {
  selectAll: selectAllDefaultFormTemplates,
  selectEntities: selectDefaultFormTemplateEntities,
  selectById: selectDefaultFormTemplateById,
  selectIds: selectDefaultFormTemplateIds,
} = defaultTemplatesAdapter.getSelectors((state) => state.projectForms.defaultTemplates);

export default projectFormSlice.reducer;
