import moment from 'moment';

import { round } from 'lodash-es';

export const createReport = (reportData, settings, generationParams) => {
  const { days, daysTypes, resources, workHourType } = reportData;
  const {
    isRecordBasedReductions,
    paidCoffeeBreakMinutes,
    isProjectHourRowsEnabled,
  } = generationParams;

  const paidCoffeeBreakLengthValueInHours = paidCoffeeBreakMinutes
    ? Number(paidCoffeeBreakMinutes) / 60
    : 0;

  let weeks = {};

  Object.keys(days).map(function (day) {
    const week1 = moment(day).endOf('isoWeek').format('YYYY[W]WW');
    const week2 = moment(day).add(1, 'weeks').format('YYYY[W]WW');
    const weekKey = `${week1}-${week2}`;
    if (
      !Object.keys(weeks).some(function (k) {
        return ~k.indexOf(week1);
      })
    ) {
      weeks[weekKey] = {};
    }
  });

  Object.keys(days).map(function (day) {
    const dayWeek = moment(day).endOf('isoWeek').format('YYYY[W]WW');
    Object.keys(weeks).map(function (week) {
      if (week.includes(dayWeek)) {
        if (!(day in weeks[week])) weeks[week][day] = days[day];
      }
    });
  });

  const mainTableHeader = (_days) => {
    let titles3 = [];

    if (settings.filetype === 'PDF') {
      titles3 = titles3.map((x) =>
        x ? { text: x, style: 'tableHeader' } : ''
      );
    }

    let totalHours = 0;
    Object.keys(_days).map(function (key) {
      const d = moment(key);

      Object.keys(_days[key]).map((resource) => {
        totalHours += _days[key][resource].hours;
      });

      if (settings.filetype === 'excel') {
        titles3.push(`${d.date()}.${d.month() + 1}.`);
      } else {
        titles3.push(
          `${d.date()}.${d.month() + 1}._ISOWEEKDAY-${d.isoWeekday()}`
        );
      }
    });

    if (totalHours > 0) {
      return titles3;
    } else {
      return [];
    }
  };

  let userTotalWorkAmountsByUserId = {};

  let totalProjectHoursByUserId = {};

  let eachDayTotalHoursByUserId = {};

  const mainTableRows = (_days) => {
    let rows = [];
    Object.keys(resources).map((resourceKey) => {
      let workdays = 0;
      let totalsum = 0;
      let totalSumWithRecordBasedReductions = 0;
      let autoReducedSum = 0;

      const resourceData = resources[resourceKey];
      let resourceName = `${resourceData.resource.firstName} ${resourceData.resource.lastName}`;
      if (settings.lastNameFirst) {
        resourceName = `${resourceData.resource.lastName} ${resourceData.resource.firstName}`;
      }
      let columns = [resourceName, '', '', ''];

      let workHoursByProjectId = {};

      let madeAutomaticProjectReductionDays = [];

      Object.keys(_days).map(function (dayKey) {
        if (
          Object.keys(daysTypes[dayKey].WORK).length ||
          Object.keys(daysTypes[dayKey].PROJECTWORK).length
        ) {
          const workTypeDataForResourceInDay =
            daysTypes[dayKey]['WORK'][resourceKey];

          const projectworkTypeDataForResourceInDay =
            daysTypes[dayKey]['PROJECTWORK'][resourceKey];

          const userHasWorkOrProjectWorkThisDay =
            workTypeDataForResourceInDay || projectworkTypeDataForResourceInDay
              ? true
              : false;

          if (userHasWorkOrProjectWorkThisDay) {
            let daySum;

            const workTypeValue = workTypeDataForResourceInDay
              ? workTypeDataForResourceInDay.value
              : 0;
            const projectWorkTypeValue = projectworkTypeDataForResourceInDay
              ? projectworkTypeDataForResourceInDay.value
              : 0;

            const workTypeReduction = workTypeDataForResourceInDay
              ? workTypeDataForResourceInDay.reduction
              : 0;
            const projectWorkTypeReduction = projectworkTypeDataForResourceInDay
              ? projectworkTypeDataForResourceInDay.reduction
              : 0;

            let thisDayEffectiveWorkValue = 0;

            if (workHourType === 'ALL') {
              workdays += 1;
              const totalWork = workTypeValue + projectWorkTypeValue;
              const totalReductions =
                workTypeReduction + projectWorkTypeReduction;

              totalsum += totalWork;
              daySum = totalWork;

              const totalEffectiveWorkValue = totalWork - totalReductions;

              autoReducedSum += totalEffectiveWorkValue;

              thisDayEffectiveWorkValue = totalEffectiveWorkValue;
            } else {
              if (workHourType === 'WORK') {
                totalsum += workTypeValue;
                daySum = workTypeValue;

                const totalEffectiveWorkValue =
                  workTypeValue - workTypeReduction;

                autoReducedSum += totalEffectiveWorkValue;

                thisDayEffectiveWorkValue = totalEffectiveWorkValue;

                if (workTypeValue > 0) {
                  workdays += 1;
                }
              } else {
                totalsum += projectWorkTypeValue;
                daySum = projectWorkTypeValue;

                const totalEffectiveWorkValue =
                  projectWorkTypeValue - projectWorkTypeReduction;

                autoReducedSum += totalEffectiveWorkValue;
                if (projectWorkTypeValue > 0) {
                  workdays += 1;
                }

                thisDayEffectiveWorkValue = totalEffectiveWorkValue;
              }
            }

            const existingMealBreakForResourceInDay =
              daysTypes[dayKey]['BREAK_MEAL'][resourceKey];

            const existingBreakForResourceInDay =
              daysTypes[dayKey]['BREAK'][resourceKey];

            const existingCoffeeBreakForResourceInDay =
              daysTypes[dayKey]['BREAK_COFFEE'][resourceKey];

            const calculatedDayReductions = calculateBreakRecordTotalReductions(
              existingBreakForResourceInDay,
              existingMealBreakForResourceInDay,
              existingCoffeeBreakForResourceInDay,
              paidCoffeeBreakLengthValueInHours
            );

            if (!eachDayTotalHoursByUserId[resourceKey]) {
              eachDayTotalHoursByUserId[resourceKey] = [];
            }

            eachDayTotalHoursByUserId[resourceKey].push({
              day: dayKey,
              hours: isRecordBasedReductions
                ? daySum - calculatedDayReductions
                : thisDayEffectiveWorkValue,
            });

            totalSumWithRecordBasedReductions +=
              daySum - calculatedDayReductions;

            const daySumValue = Number(daySum) > 0.01 ? daySum.toFixed(2) : '';

            columns.push(daySumValue);
          } else {
            columns.push('');
          }
        } else {
          columns.push('');
        }

        if (isProjectHourRowsEnabled) {
          const workedProjects = resourceData.workedProjects;

          workedProjects.forEach((project) => {
            if (!workHoursByProjectId[project.projectId]) {
              workHoursByProjectId[project.projectId] = {
                projectName: project.projectName,
                projectId: project.projectId,
                reducedTotalHours: 0,
                totalHours: 0,
                totalWorkDays: 0,
                allowanceRows: [],
                dayHourCells: [],
              };
            }

            const thisResourceProjectAdditionalSalaries =
              resourceData.additionalSalariesByProjectId[project.projectId];

            if (thisResourceProjectAdditionalSalaries) {
              workHoursByProjectId[project.projectId].allowanceRows =
                Object.values(thisResourceProjectAdditionalSalaries);
            }

            const thisResourceWorkForDay = _days[dayKey][resourceKey];

            if (thisResourceWorkForDay) {
              const thisDayProjectHours =
                _days[dayKey][resourceKey].projectHours;

              const reductionsForDay =
                _days[dayKey][resourceKey].projectHours[project.projectId] &&
                _days[dayKey][resourceKey].projectHours[project.projectId]
                  .reductions;

              let fullReductions = 0;

              if (isRecordBasedReductions && reductionsForDay) {
                fullReductions =
                  reductionsForDay.BREAK + reductionsForDay.BREAK_MEAL;
              }
              if (
                Object.keys(thisResourceWorkForDay.projectHours).includes(
                  project.projectId
                )
              ) {
                const foundWorkHourValue =
                  thisResourceWorkForDay.projectHours[project.projectId].hours;

                workHoursByProjectId[project.projectId].dayHourCells.push(
                  round(foundWorkHourValue, 2)
                );
                workHoursByProjectId[project.projectId].totalHours += round(
                  foundWorkHourValue,
                  2
                );

                const workTypeDataForResourceInDay =
                  daysTypes[dayKey]['WORK'][resourceKey];

                const projectworkTypeDataForResourceInDay =
                  daysTypes[dayKey]['PROJECTWORK'][resourceKey];

                const userHasWorkOrProjectWorkThisDay =
                  workTypeDataForResourceInDay ||
                  projectworkTypeDataForResourceInDay
                    ? true
                    : false;

                const workTypeValue = workTypeDataForResourceInDay
                  ? workTypeDataForResourceInDay.value
                  : 0;
                const projectWorkTypeValue = projectworkTypeDataForResourceInDay
                  ? projectworkTypeDataForResourceInDay.value
                  : 0;

                if (userHasWorkOrProjectWorkThisDay) {
                  if (
                    !isRecordBasedReductions &&
                    (workTypeValue >= 6 || projectWorkTypeValue >= 6)
                  ) {
                    const isExistingProjectHoursForDayWithMealBreakReduction =
                      Object.values(thisDayProjectHours).some(
                        (projectHours) =>
                          projectHours.reductions
                            .AUTOMATIC_MEAL_BREAK_REDUCTION > 0
                      );

                    if (!madeAutomaticProjectReductionDays.includes(dayKey)) {
                      if (reductionsForDay.AUTOMATIC_MEAL_BREAK_REDUCTION > 0) {
                        fullReductions =
                          reductionsForDay.AUTOMATIC_MEAL_BREAK_REDUCTION;
                        madeAutomaticProjectReductionDays.push(dayKey);
                      } else if (
                        foundWorkHourValue > 1 &&
                        !isExistingProjectHoursForDayWithMealBreakReduction
                      ) {
                        fullReductions = 0.5;
                        madeAutomaticProjectReductionDays.push(dayKey);
                      }
                    }
                  }
                }

                const reducedHours = foundWorkHourValue - fullReductions;

                workHoursByProjectId[project.projectId].reducedTotalHours +=
                  reducedHours;

                workHoursByProjectId[project.projectId].totalWorkDays += 1;
              } else {
                workHoursByProjectId[project.projectId].dayHourCells.push('');
              }
            } else {
              workHoursByProjectId[project.projectId].dayHourCells.push('');
            }
          });
        }
      });

      if (
        totalsum > 0 ||
        Object.keys(resourceData.additionalSalaries.length > 0)
      ) {
        const finalReducedSum = !isRecordBasedReductions
          ? autoReducedSum.toFixed(2)
          : totalSumWithRecordBasedReductions.toFixed(2);

        const userSalaryType = resourceData.resource.baseSalaryType;

        const salaryUnitPrice =
          userSalaryType === 'MONTHLY'
            ? resourceData.resource.salaryMonth
            : resourceData.resource.salaryHour;

        const userHourlySalary = resourceData.resource.salaryHour || 0;
        const userMonthlySalary = resourceData.resource.salaryMonth || 0;

        const monthSalaryFrequency = resourceData.resource.monthSalaryFrequency;

        columns[1] = workdays;
        columns[2] = totalsum.toFixed(2);
        columns[3] = finalReducedSum;

        rows.push({
          userId: resourceKey,
          cells: columns,
          baseSalaryType: userSalaryType,
          salaryUnitPrice,
          projectHours: Object.values(workHoursByProjectId).filter(
            (projectHours) => projectHours.totalHours > 0
          ),
        });

        const foundUserTotals = userTotalWorkAmountsByUserId[resourceKey];

        // calculate each project worked total hours
        Object.values(workHoursByProjectId).forEach((projectHours) => {
          if (!totalProjectHoursByUserId[resourceKey]) {
            totalProjectHoursByUserId[resourceKey] = {};
          }
          const foundHours =
            totalProjectHoursByUserId[resourceKey][projectHours.projectId];

          if (!foundHours) {
            totalProjectHoursByUserId[resourceKey][projectHours.projectId] = {
              totalHours: 0,
              reducedTotalHours: 0,
            };
          }

          totalProjectHoursByUserId[resourceKey][
            projectHours.projectId
          ].totalHours += projectHours.totalHours;

          totalProjectHoursByUserId[resourceKey][
            projectHours.projectId
          ].reducedTotalHours += projectHours.reducedTotalHours;
        });

        if (foundUserTotals) {
          foundUserTotals.workTotalHours += Number(totalsum);
          foundUserTotals.workReducedHours += Number(finalReducedSum);
        } else {
          userTotalWorkAmountsByUserId[resourceKey] = {
            workTotalHours: Number(totalsum),
            workReducedHours: Number(finalReducedSum),
            userId: resourceKey,
            baseSalaryType: userSalaryType,
            unitPrice:
              userSalaryType === 'MONTHLY'
                ? userMonthlySalary
                : userHourlySalary,
            monthSalaryFrequency,
            projectTotals: totalProjectHoursByUserId[resourceKey],
            eachDayTotalHours: eachDayTotalHoursByUserId[resourceKey],
          };
        }
      }
    });
    return rows;
  };

  let dataRows = [];
  let headerRows = [];
  Object.keys(weeks).map(function (week) {
    const _days = weeks[week];
    dataRows.push(mainTableRows(_days));
    headerRows.push(mainTableHeader(_days));
  });

  return { dataRows, headerRows, userTotalWorkAmountsByUserId };
};

const calculateBreakRecordTotalReductions = (
  breakRecordData,
  mealBreakRecordData,
  coffeeBreakRecordData,
  paidCoffeeBreakHours
) => {
  let totalReductions = 0;

  if (breakRecordData) {
    totalReductions += breakRecordData.value;
  }

  if (mealBreakRecordData) {
    totalReductions += mealBreakRecordData.value;
  }

  if (
    coffeeBreakRecordData &&
    coffeeBreakRecordData.value > paidCoffeeBreakHours
  ) {
    const coffeeBreakLengthValueWithPaidPortionReduced =
      coffeeBreakRecordData.value - paidCoffeeBreakHours;

    totalReductions += coffeeBreakLengthValueWithPaidPortionReduced;
  }
  return totalReductions;
};
