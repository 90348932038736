import { createAsyncThunk } from '@reduxjs/toolkit';
import getCredentials from '../../utils/getCredentials';
import setSendJoiningInstruction from 'scenes/BulletinBoard/utils/setSendJoiningInstruction';

export const sendJoiningInstruction = createAsyncThunk(
  'bulletinBoard/sendJoiningInstruction',
  async (body, { getState }) => {

    const projectId = getState().projects.selectedProjectId;

    const { accessKeyId, secretAccessKey, sessionToken } =
      await getCredentials();

    const params = {
      accessKeyId,
      secretAccessKey,
      sessionToken,
    };

    const response = await setSendJoiningInstruction(body, params, projectId);

    return { response };
  }
);
