import moment from 'moment';
import { RECORD_TYPE } from './enums';
const processes = require('./processes');

export const processData = (reportData, settings) => {
  /**
   * Used to trim reportData records time to work time range
   * @returns {Object} reportData
   */
  const trimRecordsTime = () => {
    const { workStartTime, workEndTime, records } = reportData;

    const filteredRecords = records.filter((record) => {
      const utcOffsetClockInAt = moment(record.clockInAt);

      const utcOffsetClockOutAt = moment(record.clockOutAt);

      const clockInMinutes =
        parseInt(utcOffsetClockInAt.format('HH')) * 60 +
        parseInt(utcOffsetClockInAt.format('mm'));

      const clockInAndClockOutMinuteDiff = utcOffsetClockOutAt.diff(
        utcOffsetClockInAt,
        'minutes',
        true
      );

      const clockOutTotalMinutes =
        clockInMinutes + clockInAndClockOutMinuteDiff;

      const workStartTimeMinutes =
        parseInt(workStartTime.split(':')[0]) * 60 +
        parseInt(workStartTime.split(':')[1]);

      const workEndTimeMinutes =
        parseInt(workEndTime.split(':')[0]) * 60 +
        parseInt(workEndTime.split(':')[1]);

      // If clockIn is after workEndTime or clockOut is before workStartTime, do nothing and return
      if (
        clockInMinutes < workEndTimeMinutes &&
        clockOutTotalMinutes > workStartTimeMinutes
      )
        return record;
    });

    const croppedRecords = filteredRecords.map((record) => {
      const utcOffsetClockInAt = moment(record.clockInAt);

      const utcOffsetClockOutAt = moment(record.clockOutAt);

      const clockInMinutes =
        parseInt(utcOffsetClockInAt.format('HH')) * 60 +
        parseInt(utcOffsetClockInAt.format('mm'));
      const clockOutMinutes =
        parseInt(utcOffsetClockOutAt.format('HH')) * 60 +
        parseInt(utcOffsetClockOutAt.format('mm'));

      const workStartTimeMinutes =
        parseInt(workStartTime.split(':')[0]) * 60 +
        parseInt(workStartTime.split(':')[1]);

      const workEndTimeMinutes =
        parseInt(workEndTime.split(':')[0]) * 60 +
        parseInt(workEndTime.split(':')[1]);

      if (clockInMinutes < workStartTimeMinutes) {
        record.croppedClockInAt = moment(record.clockInAt)
          .startOf('day')
          .add(workStartTimeMinutes, 'minutes')
          .toISOString();
      } else {
        record.croppedClockInAt = record.clockInAt;
      }
      if (clockOutMinutes > workEndTimeMinutes) {
        record.croppedClockOutAt = moment(record.clockOutAt)
          .startOf('day')
          .add(workEndTimeMinutes, 'minutes')
          .toISOString();
      } else {
        record.croppedClockOutAt = record.clockOutAt;
      }
      return record;
    });

    reportData.records = croppedRecords;
    return reportData;
  };

  const initDays = () => {
    const fromDate = moment(reportData.fromDate).utcOffset(
      reportData.utcOffset,
      false
    );
    const toDate = moment(reportData.toDate).utcOffset(
      reportData.utcOffset,
      false
    );

    let days = {};
    let daysTypes = {};
    let i = fromDate;
    while (i.format('YYYY-MM-DD') <= toDate.format('YYYY-MM-DD')) {
      const day = i.format('YYYY-MM-DD');

      if (!(day in days)) {
        days[day] = {};
        daysTypes[day] = {};
        daysTypes[day][RECORD_TYPE.WORK] = {};
        daysTypes[day][RECORD_TYPE.PROJECTWORK] = {};
        daysTypes[day][RECORD_TYPE.ABSENCE] = {};
        daysTypes[day][RECORD_TYPE.ABSENCE_VACATION_PAID] = {};
        daysTypes[day][RECORD_TYPE.ABSENCE_SICK_LEAVE] = {};
        daysTypes[day][RECORD_TYPE.BREAK] = {};
        daysTypes[day][RECORD_TYPE.BREAK_COFFEE] = {};
        daysTypes[day][RECORD_TYPE.BREAK_MEAL] = {};
        daysTypes[day][RECORD_TYPE.SHIFT] = {};
        daysTypes[day][RECORD_TYPE.RIDE] = {};
        daysTypes[day][RECORD_TYPE.BENEFIT] = {};
        daysTypes[day][RECORD_TYPE.ITEM] = {};
        daysTypes[day][RECORD_TYPE.NEGATIVE] = {};
      }
      i.add(1, 'days');
    }
    reportData.days = days;
    reportData.daysTypes = daysTypes;

    return reportData;
  };

  const baseHours = () => {
    const records = reportData.records;
    let days = reportData.days;
    let daysTypes = reportData.daysTypes;

    const { recordAllowancesGroupedByRecordId } = reportData;

    const recordsWithExistingResource = records.filter(
      (record) => record.resource
    );

    let sortedRecords = [];

    try {
      recordsWithExistingResource.sort((a, b) =>
        a.croppedClockInAt.localeCompare(b.croppedClockInAt)
      );
      if (settings.lastNameFirst) {
        sortedRecords = recordsWithExistingResource.sort((a, b) =>
          a.resource.lastName.localeCompare(
            b.resource.lastName,
            settings.language,
            { sensitivity: 'base' }
          )
        );
      } else {
        sortedRecords = recordsWithExistingResource.sort((a, b) =>
          a.resource.firstName.localeCompare(
            b.resource.firstName,
            settings.language,
            { sensitivity: 'base' }
          )
        );
      }
    } catch (err) {
      console.log('ERROR 1');
    }

    let resources = {};

    sortedRecords.forEach((record) => {
      let clockOut = null;
      if (record.croppedClockOutAt) {
        clockOut = new Date(record.croppedClockOutAt);
      }

      if (clockOut) {
        let endDay = moment(record.croppedClockOutAt)
          .utc()
          .utcOffset(reportData.utcOffset, false);
        let i = moment(record.croppedClockInAt)
          .utc()
          .utcOffset(reportData.utcOffset, false);

        while (i.format('YYYY-MM-DD') <= endDay.format('YYYY-MM-DD')) {
          const day = i.format('YYYY-MM-DD');

          if (Object.keys(days).includes(day)) {
            if (!(day in days)) {
              days[day] = {};
            }
            const resource = record.resource;
            if (!(resource.user in days[day])) {
              days[day][resource.user] = {
                resource,
                hours: 0,
                reduction: 0,
                projectHours: {},
              };
            }
            if (daysTypes[day][record.type] !== undefined) {
              if (!(resource.user in daysTypes[day][record.type])) {
                daysTypes[day][record.type][resource.user] = {
                  value: 0,
                  reduction: 0,
                };
              }
            }
            if (!(resource.user in resources)) {
              resources[resource.user] = {
                resource,
                totalHours: 0,
                days: {},
                daysTypes: {},
                totals: {},
                reductions: {},
                automaticReductions: 0,
                seperateAdditionalSalaries: [],
                additionalSalaries: {},
                additionalSalariesByProjectId: {},
                workedProjects: [],
                dailyProjectsAtMealBreak: {},
              };
              resources[resource.user].totals[RECORD_TYPE.ABSENCE] = 0;
              resources[resource.user].totals[
                RECORD_TYPE.ABSENCE_SICK_LEAVE
              ] = 0;
              resources[resource.user].totals[
                RECORD_TYPE.ABSENCE_VACATION_PAID
              ] = 0;
              resources[resource.user].totals[RECORD_TYPE.BENEFIT] = 0;
              resources[resource.user].totals[RECORD_TYPE.BREAK] = 0;
              resources[resource.user].totals[RECORD_TYPE.BREAK_COFFEE] = 0;
              resources[resource.user].totals[RECORD_TYPE.BREAK_MEAL] = 0;
              resources[resource.user].totals[RECORD_TYPE.ITEM] = 0;
              resources[resource.user].totals[RECORD_TYPE.RIDE] = 0;
              resources[resource.user].totals[RECORD_TYPE.NEGATIVE] = 0;
              resources[resource.user].totals[RECORD_TYPE.SHIFT] = 0;
              resources[resource.user].totals[RECORD_TYPE.WORK] = 0;
              resources[resource.user].totals[RECORD_TYPE.PROJECTWORK] = 0;
              resources[resource.user].reductions[RECORD_TYPE.WORK] = 0;
              resources[resource.user].reductions[RECORD_TYPE.PROJECTWORK] = 0;
              resources[resource.user].totals.reductionsByProject = {};
            }
            if (!(day in resources[resource.user].days)) {
              resources[resource.user].days[day] = {
                hours: 0,
                reduction: 0,
              };
            }
            if (!(day in resources[resource.user].daysTypes)) {
              resources[resource.user].daysTypes[day] = {};
            }
            if (!(record.type in resources[resource.user].daysTypes[day])) {
              resources[resource.user].daysTypes[day][record.type] = {
                value: 0,
                reduction: 0,
              };
            }

            const co = moment.min(
              moment(record.croppedClockOutAt),
              moment(i).set({
                hours: 23,
                minute: 59,
                second: 59,
                millisecond: 999,
              })
            );
            const ci = moment.max(
              moment(record.croppedClockInAt),
              moment(i).set({
                hours: 0,
                minute: 0,
                second: 0,
                millisecond: 0,
              })
            );
            const hours = co.diff(ci, 'hours', true);

            days[day][resource.user].hours += hours;
            if (days[day][resource.user].hours > 6) {
              days[day][resource.user].reduction = 0.5;
            }

            daysTypes[day][record.type][resource.user].value += hours;

            // count break record reductions for each project
            if (record.type.includes('BREAK')) {
              const existingCountedReductionsForProject =
                resources[resource.user].totals.reductionsByProject[
                  record.project.id
                ];

              if (!existingCountedReductionsForProject) {
                resources[resource.user].totals.reductionsByProject[
                  record.project.id
                ] = {
                  BREAK_MEAL: 0,
                  BREAK_COFFEE: 0,
                  BREAK: 0,
                  AUTOMATIC_MEAL_BREAK_REDUCTION: 0,
                };
              }
              resources[resource.user].totals.reductionsByProject[
                record.project.id
              ][record.type] += hours;

              if (!days[day][resource.user].projectHours[record.project.id]) {
                days[day][resource.user].projectHours[record.project.id] = {
                  hours: 0,
                  projectName: record.project.name,
                  reductions: {
                    BREAK_MEAL: 0,
                    BREAK_COFFEE: 0,
                    BREAK: 0,
                    AUTOMATIC_MEAL_BREAK_REDUCTION: 0,
                  },
                };
              }
              days[day][resource.user].projectHours[
                record.project.id
              ].reductions[record.type] += hours;
            }

            if (
              record.type === RECORD_TYPE.WORK ||
              record.type === RECORD_TYPE.PROJECTWORK
            ) {
              if (daysTypes[day][record.type][resource.user].value > 6) {
                if (!daysTypes[day][record.type][resource.user].reduction) {
                  daysTypes[day][record.type][resource.user].reduction = 0.5;
                  resources[resource.user].automaticReductions += 0.5;
                }
              }

              if (!days[day][resource.user].projectHours[record.project.id]) {
                days[day][resource.user].projectHours[record.project.id] = {
                  hours: 0,
                  projectName: record.project.name,
                  reductions: {
                    BREAK_MEAL: 0,
                    BREAK_COFFEE: 0,
                    BREAK: 0,
                    AUTOMATIC_MEAL_BREAK_REDUCTION: 0,
                  },
                };
              }

              days[day][resource.user].projectHours[record.project.id].hours +=
                hours;

              const recordAllowances = recordAllowancesGroupedByRecordId
                ? recordAllowancesGroupedByRecordId[record.id]
                : undefined;

              if (recordAllowances && recordAllowances.length > 0) {
                recordAllowances.forEach((recordAllowance) => {
                  const existingAddedAdditionalSalaryData =
                    resources[resource.user].additionalSalaries[
                      recordAllowance.salaryType
                    ];
                  const date = moment(record.croppedClockInAt).format('D.M.');

                  const fullDate = moment(record.croppedClockInAt).format(
                    'DD.MM.YYYY'
                  );

                  resources[resource.user].seperateAdditionalSalaries.push({
                    unitCount: recordAllowance.unitCount,
                    date: fullDate,
                    salaryType: recordAllowance.salaryType,
                  });

                  if (existingAddedAdditionalSalaryData) {
                    existingAddedAdditionalSalaryData.unitCount += Number(
                      recordAllowance.unitCount
                    );
                    existingAddedAdditionalSalaryData.definedDates.push(date);
                    existingAddedAdditionalSalaryData.dayUnitCount[date] =
                      Number(recordAllowance.unitCount);
                  } else {
                    resources[resource.user].additionalSalaries[
                      recordAllowance.salaryType
                    ] = {
                      unitCount: Number(recordAllowance.unitCount),
                      definedDates: [date],
                      salaryType: recordAllowance.salaryType,
                      dayUnitCount: {
                        [date]: Number(recordAllowance.unitCount),
                      },
                    };
                  }
                  if (
                    !resources[resource.user].additionalSalariesByProjectId[
                      recordAllowance.projectId
                    ]
                  ) {
                    resources[resource.user].additionalSalariesByProjectId[
                      recordAllowance.projectId
                    ] = {};
                  }

                  const existingAdditionalSalaryByProject =
                    resources[resource.user].additionalSalariesByProjectId[
                      recordAllowance.projectId
                    ][recordAllowance.salaryType];

                  if (existingAdditionalSalaryByProject) {
                    existingAdditionalSalaryByProject.unitCount += Number(
                      recordAllowance.unitCount
                    );
                    existingAdditionalSalaryByProject.definedDates.push(date);
                    existingAdditionalSalaryByProject.dayUnitCount[date] =
                      Number(recordAllowance.unitCount);
                  } else {
                    resources[resource.user].additionalSalariesByProjectId[
                      recordAllowance.projectId
                    ][recordAllowance.salaryType] = {
                      projectId: recordAllowance.projectId,
                      unitCount: Number(recordAllowance.unitCount),
                      definedDates: [date],
                      salaryType: recordAllowance.salaryType,
                      dayUnitCount: {
                        [date]: Number(recordAllowance.unitCount),
                      },
                    };
                  }
                });
              }

              if (
                !resources[resource.user].workedProjects.find(
                  (workedProject) =>
                    workedProject.projectId === record.project.id
                )
              ) {
                resources[resource.user].workedProjects.push({
                  projectName: record.project.name,
                  projectId: record.project.id,
                });
              }

              const recordStartTime = moment(ci);
              const recordEndTime = moment(co);

              const timeframeStart = moment(i).set({
                hour: 11,
                minute: 0,
                second: 0,
              });
              const timeframeEnd = moment(i).set({
                hour: 11,
                minute: 30,
                second: 0,
              });

              const overlapsMealBreakTimeframe =
                recordStartTime.isBefore(timeframeEnd) &&
                recordEndTime.isAfter(timeframeStart);

              if (overlapsMealBreakTimeframe) {
                if (!resources[resource.user].dailyProjectsAtMealBreak[day]) {
                  resources[resource.user].dailyProjectsAtMealBreak[day] = [];
                }

                const dailyMealProjects =
                  resources[resource.user].dailyProjectsAtMealBreak[day];

                const projectAlreadyCounted = dailyMealProjects.includes(
                  record.project.id
                );
                if (!projectAlreadyCounted) {
                  dailyMealProjects.push(record.project.id);

                  // Apply reduction to project hours
                  dailyMealProjects.forEach((projectId) => {
                    // Apply equal portion of reduction to each project
                    const reductionPerProject = 0.5 / dailyMealProjects.length;

                    days[day][resource.user].projectHours[projectId].reductions[
                      'AUTOMATIC_MEAL_BREAK_REDUCTION'
                    ] = reductionPerProject;

                    //count reductions to total proejct reductions
                    const existingCountedReductionsForProject =
                      resources[resource.user].totals.reductionsByProject[
                        record.project.id
                      ];
                    if (!existingCountedReductionsForProject) {
                      resources[resource.user].totals.reductionsByProject[
                        record.project.id
                      ] = {
                        BREAK_MEAL: 0,
                        BREAK_COFFEE: 0,
                        BREAK: 0,
                        AUTOMATIC_MEAL_BREAK_REDUCTION: 0,
                      };
                    }

                    resources[resource.user].totals.reductionsByProject[
                      record.project.id
                    ]['AUTOMATIC_MEAL_BREAK_REDUCTION'] += 0.5;
                  });
                }
              }
            } else if (
              record.type === RECORD_TYPE.ABSENCE_SICK_LEAVE ||
              record.type === RECORD_TYPE.ABSENCE_VACATION_PAID
            ) {
              // handle global resource additional salary data
              const existingAddedAdditionalSalaryData =
                resources[resource.user].additionalSalaries[record.type];
              const date = moment(record.croppedClockInAt).format('D.M.');
              const fullDate = moment(record.croppedClockInAt).format(
                'DD.MM.YYYY'
              );

              const salaryType =
                record.type === RECORD_TYPE.ABSENCE_SICK_LEAVE
                  ? 'SICK_LEAVE_PAY'
                  : 'HOLIDAY_PAY';

              resources[resource.user].seperateAdditionalSalaries.push({
                unitCount: 1,
                date: fullDate,
                salaryType,
              });

              if (existingAddedAdditionalSalaryData) {
                existingAddedAdditionalSalaryData.unitCount += 1;
                existingAddedAdditionalSalaryData.definedDates.push(date);
              } else {
                resources[resource.user].additionalSalaries[record.type] = {
                  unitCount: 1,
                  definedDates: [date],
                  salaryType,
                };
              }

              // handle project specific additional salary data adding
              if (
                !resources[resource.user].additionalSalariesByProjectId[
                  record.project.id
                ]
              ) {
                resources[resource.user].additionalSalariesByProjectId[
                  record.project.id
                ] = {};
              }

              const existingAdditionalSalaryByProject =
                resources[resource.user].additionalSalariesByProjectId[
                  record.project.id
                ][record.type];

              if (existingAdditionalSalaryByProject) {
                existingAdditionalSalaryByProject.unitCount += 1;
                existingAdditionalSalaryByProject.definedDates.push(date);
              } else {
                resources[resource.user].additionalSalariesByProjectId[
                  record.project.id
                ][record.type] = {
                  unitCount: 1,
                  definedDates: [date],
                  salaryType:
                    record.type === RECORD_TYPE.ABSENCE_SICK_LEAVE
                      ? 'SICK_LEAVE_PAY'
                      : 'HOLIDAY_PAY',
                };
              }
            }
            resources[resource.user].totalHours += hours;
            resources[resource.user].totals[record.type] += hours;
          }
          i.add(1, 'days');
        }
      }
    });
    reportData['resources'] = resources;
    return reportData;
  };

  const runProcesses = () => {
    settings.processes.forEach((process) => {
      reportData = processes[process.type](reportData, process);
    });
    return reportData;
  };

  reportData = trimRecordsTime();
  reportData = initDays();
  reportData = baseHours();
  reportData = runProcesses();

  return reportData;
};
