import { createAsyncThunk } from '@reduxjs/toolkit';
import { selectSelectedProject } from '../selectors/projectsSelectors';
import { getIntegrationPartnerByBusinessId } from '../services/getIntegrationPartnerByBusinessId';

export const loadBusinessIntegrationPartners = createAsyncThunk(
  'projects/loadBusinessIntegrationPartners',
  async (_, { getState }) => {
    const selectedProject = selectSelectedProject(getState());

    const businessId = selectedProject.business_id;

    if (selectedProject.businessTypeEnum === 'EMPLOYER') {
      const integrationPartner = await getIntegrationPartnerByBusinessId(
        selectedProject.business_id
      );

      return { businessId, integrationPartner };
    }
  }
);
