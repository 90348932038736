import { createAsyncThunk } from '@reduxjs/toolkit';
import { postCreateCustomer } from '../services/postCreateCustomer.service';
import { selectSelectedProject } from 'store/projects/selectors/projectsSelectors';

export const createCustomer = createAsyncThunk(
  'customer/createCustomer',
  async (data, { getState }) => {
    const customerData = data;

    const selectedProject = selectSelectedProject(getState());
    const businessId = selectedProject.business_id;
    if (!businessId) {
      throw new Error('No business id given');
    }

    const customerCreationData = await postCreateCustomer(
      businessId,
      customerData
    );

    return customerCreationData;
  }
);
