import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  selectReportPreviewWeeklyPeriodTableData,
  selectReportUserRowTotals,
  selectWeekIndexedDayWorkHoursOfEmployeeToAdd,
  selectWeekIndexedEmployeeNamesToAdd,
} from '../selectors/reports.selectors';
import { cloneDeep, round } from 'lodash';
import {
  addManualUserRowTotals,
  initAddEmployeeForm,
  setEmployeeNameToAdd,
} from '../actions/reports.actions';
import uuidv4 from 'uuid/v4';

export const addReportPreviewUserRow = createAsyncThunk(
  'reportPreview/addReportPreviewUserRow',
  async (payload, { getState, dispatch }) => {
    const weekIndexedDayWorkHoursOfEmployeeToAdd =
      selectWeekIndexedDayWorkHoursOfEmployeeToAdd(getState());

    const employeeNamesToAdd = selectWeekIndexedEmployeeNamesToAdd(getState());

    const weekTableIndex = payload.weekTableIndex;

    const personNumber = payload.personNumber;

    const dayHoursToSet = Object.values(
      weekIndexedDayWorkHoursOfEmployeeToAdd[weekTableIndex]
    );

    const userRowTotals = selectReportUserRowTotals(getState());

    const eachDayHourValues = dayHoursToSet.map((day) => day.value);

    const activeWorkDays = dayHoursToSet.filter((day) => day.value > 0);

    const totalDaysAmount = activeWorkDays.length;

    const weeklyTableData = selectReportPreviewWeeklyPeriodTableData(
      getState()
    );

    const modifiablWeeklyTableData = cloneDeep(weeklyTableData);

    let totalWorkHours = 0;
    let automaticReductions = 0;

    activeWorkDays.forEach((day) => {
      totalWorkHours += day.value;
      if (day.value > 6) {
        automaticReductions += 0.5;
      }
    });

    const employeeName = employeeNamesToAdd[weekTableIndex];

    const foundExistingUserByName = Object.values(userRowTotals).find(
      (userRow) => userRow.name === employeeName
    );

    const manualRowUserId = foundExistingUserByName
      ? foundExistingUserByName.userId
      : `custom-added-user-${uuidv4()}`;

    const roundedTotalWorkHours = round(totalWorkHours, 2);

    const roundedReducedHours = round(totalWorkHours - automaticReductions, 2);

    modifiablWeeklyTableData[weekTableIndex].dataRows.push({
      userId: manualRowUserId,
      cells: [
        employeeName,
        totalDaysAmount,
        roundedTotalWorkHours,
        roundedReducedHours,
        ...eachDayHourValues,
      ],
    });

    dispatch(
      addManualUserRowTotals({
        salaryType: 'WORK',
        unitCount: roundedReducedHours,
        unitCountWithoutReductions: roundedTotalWorkHours,
        name: employeeName,
        userId: manualRowUserId,
        personNumber,
      })
    );

    dispatch(initAddEmployeeForm(weekTableIndex));
    dispatch(setEmployeeNameToAdd({ weekIndex: weekTableIndex, name: '' }));

    return modifiablWeeklyTableData;
  }
);
