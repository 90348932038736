export const returnSentReportDataToSave = ({
  selectedProjectId,
  weeklyTableData,
  userAdditionalSalaryRows,
  reportBasicData,
  state,
  rowComments,
  reportUserRowTotals,
  isRecordBasedReductions,
}) => {
  const totalRows = [];

  Object.values(reportUserRowTotals).forEach((row) => {
    totalRows.push({
      name: row.name,
      userId: row.userId,
      salaryType: row.salaryType,
      unitCount: row.unitCount,
      unitCountWithoutReductions: row.unitCountWithoutReductions,
      personNumber: row.personNumber,
    });
  });

  const { reportType, fromDate, toDate } = reportBasicData;

  const reportData = {
    projectId: selectedProjectId,
    tableDataByWeekPeriod: weeklyTableData,
    userAdditionalSalaryRows,
    reportType,
    fromDate,
    toDate,
    state,
    rowComments,
    totals: totalRows,
    isRecordBasedReductions,
  };

  return reportData;
};
