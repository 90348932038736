import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { enqueueSnackbar } from 'notistack';
import {
  clearAllEmployerAllowanceDrafts,
  removeEmployerAllowanceDraft,
  updateEmployerAllowanceDraft,
} from './actions/employerAllowances.actions';
import { createEmployerAllowanceDraft } from './thunks/createEmployerAllowanceDraft.thunk';
import { createOrUpdateEmployerAllowancesFromDrafts } from './thunks/createOrUpdateEmployerAllowancesFromDrafts.thunk';
import { loadEmployerAllowancesByBusinessId } from './thunks/loadEmployerAllowancesByBusinessId.thunk';

const employerAllowanceDraftsAdapter = createEntityAdapter();
const employerAllowancesAdapter = createEntityAdapter();

export const employerAllowancesSlice = createSlice({
  name: 'employerAllowances',
  initialState: {
    loading: false,
    employerAllowanceDrafts: employerAllowanceDraftsAdapter.getInitialState(),
    employerAllowances: employerAllowancesAdapter.getInitialState(),
    savePending: false,
  },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(createEmployerAllowanceDraft.fulfilled, (state, action) => {
        employerAllowanceDraftsAdapter.setOne(
          state.employerAllowanceDrafts,
          action.payload
        );
      })
      .addCase(updateEmployerAllowanceDraft, (state, action) => {
        const { id } = action.payload;
        let changes = action.payload.changes;

        employerAllowanceDraftsAdapter.updateOne(
          state.employerAllowanceDrafts,
          { id, changes }
        );
      })
      .addCase(removeEmployerAllowanceDraft, (state, action) => {
        const id = action.payload;
        employerAllowanceDraftsAdapter.removeOne(
          state.employerAllowanceDrafts,
          id
        );
      })
      .addCase(
        createOrUpdateEmployerAllowancesFromDrafts.pending,
        (state, action) => {
          state.savePending = true;
        }
      )
      .addCase(
        createOrUpdateEmployerAllowancesFromDrafts.rejected,
        (state, action) => {
          state.savePending = false;
          enqueueSnackbar('Palkkalajien tallentaminen epäonnistui', {
            variant: 'error',
          });
        }
      )
      .addCase(
        createOrUpdateEmployerAllowancesFromDrafts.fulfilled,
        (state, action) => {
          const employerAllowances = action.payload;

          employerAllowancesAdapter.upsertMany(
            state.employerAllowances,
            employerAllowances
          );

          employerAllowanceDraftsAdapter.removeAll(
            state.employerAllowanceDrafts
          );
          state.savePending = false;

          enqueueSnackbar('Palkkalajit tallennettu onnistuneesti', {
            variant: 'success',
          });
        }
      )
      .addCase(loadEmployerAllowancesByBusinessId.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(loadEmployerAllowancesByBusinessId.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(
        loadEmployerAllowancesByBusinessId.fulfilled,
        (state, action) => {
          employerAllowancesAdapter.upsertMany(
            state.employerAllowances,
            action.payload
          );

          state.loading = false;
        }
      )
      .addCase(clearAllEmployerAllowanceDrafts, (state, action) => {
        employerAllowanceDraftsAdapter.removeAll(state.employerAllowanceDrafts);
      });
  },
});
