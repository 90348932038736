import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { loadCustomerByBusinessId } from './thunks/loadCustomersByBusinessId.thunk';
import { createCustomer } from './thunks/createCustomer.thunk';
import { updateCustomer } from './thunks/updateCustomer.thunk';

const customerAdapter = createEntityAdapter();

const customerSlice = createSlice({
  name: 'customers',
  initialState: {
    customers: customerAdapter.getInitialState(),
    loading: false,
    customersLoaded: false,
  },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(loadCustomerByBusinessId.fulfilled, (state, action) => {
        customerAdapter.setMany(state.customers, action.payload);
        state.customersLoaded = true;
      })
      .addCase(createCustomer.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(createCustomer.fulfilled, (state, action) => {
        customerAdapter.setOne(state.customers, action.payload);
        state.loading = false;
      })
      .addCase(createCustomer.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(updateCustomer.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(updateCustomer.fulfilled, (state, action) => {
        customerAdapter.upsertOne(state.customers, action.payload);
        state.loading = false;
      })
      .addCase(updateCustomer.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export default customerSlice.reducer;
