import { createAsyncThunk } from '@reduxjs/toolkit';

import postEmployerAllowance from '../services/postEmployerAllowance.service';
import {
  selectEmployerAllowanceDraftsArray,
  selectEmployerAllowancesIds,
} from '../selectors/employerAllowances.selectors';
import { selectSelectedProject } from 'store/projects/selectors/projectsSelectors';
import updateEmployerAllowance from '../services/updateEmployerAllowance.service';

export const createOrUpdateEmployerAllowancesFromDrafts = createAsyncThunk(
  'employerAllowances/createOrUpdateEmployerAllowancesFromDrafts',
  async (_, { getState }) => {
    const drafts = selectEmployerAllowanceDraftsArray(getState());
    const selectedProject = selectSelectedProject(getState());

    const businessId = selectedProject.business_id;

    const employerAllowanceIds = selectEmployerAllowancesIds(getState());

    const employerAllowancesToCreate = drafts.filter(
      (draft) => !employerAllowanceIds.includes(draft.id)
    );

    // draft is same as the allowance id if it already exists.
    const employerAllowancesToUpdate = drafts.filter((draft) =>
      employerAllowanceIds.includes(draft.id)
    );

    const createdEmployerAllowances = await Promise.all(
      employerAllowancesToCreate.map((draft) =>
        postEmployerAllowance(businessId, draft)
      )
    );

    const updatedEmployerAllowances = await Promise.all(
      employerAllowancesToUpdate.map((draft) => {
        const updateAttributes = {
          unit: draft.unit,
          salaryType: draft.salaryType,
          allowedValues: draft.allowedValues,
          integrationPlatform: draft.integrationPlatform,
          integrationSalaryCode: draft.integrationSalaryCode,
          deletedAt: draft.deletedAt,
        };

        return updateEmployerAllowance(businessId, draft.id, updateAttributes);
      })
    );

    return [...createdEmployerAllowances, ...updatedEmployerAllowances];
  }
);
