import { createAction } from '@reduxjs/toolkit';

export const setLoading = createAction('defectList/setLoading');

export const resetDefects = createAction('defectList/resetDefects');

export const setDefectState = createAction('defectList/setDefectState');

export const setSnackBarState = createAction('defectList/setSnackBarState');

export const setCompanyLogo = createAction('defectList/setCompanyLogo');

export const clearDefectState = createAction('defectList/clearDefectState');

export const openCreateDefectListDialog = createAction(
  'defectList/openCreateDefectListDialog'
);

export const closeCreateDefectListDialog = createAction(
  'defectList/closeCreateDefectListDialog'
);

export const addDefectItemToCreate = createAction(
  'defectList/addDefectItemToCreate'
);

export const modifyDefectItemToCreate = createAction(
  'defectList/modifyDefectItemToCreate'
);

export const modifyDefectFixAttribute = createAction(
  'defectList/modifyDefectFixAttribute'
);

export const modifyDefectAdditionAttributes = createAction(
  'defectList/modifyDefectAdditionAttributes'
);

export const editDefectAttributes = createAction(
  'defectList/editDefectAttributes'
);

export const openDefectList = createAction('defectList/openDefectList');

export const modfiyDefectList = createAction('defectList/modifyDefectList');

export const setDefectAssignation = createAction(
  'defectList/setDefectAssignation'
);

export const setDefectEditAssignation = createAction(
  'defectList/setDefectEditAssignation'
);

export const setMultipleDefectAssignation = createAction(
  'defectList/setMultipleDefectAssignation'
);

export const removeDefectAssignation = createAction(
  'defectList/removeDefectAssignation'
);

export const removeDefectEditAssignation = createAction(
  'defectList/removeDefectEditAssignation'
);

export const closeViewAndEditDefectListDialog = createAction(
  'defectList/closeViewAndEditDefectListDialog'
);

export const toggleEditListAttributes = createAction(
  'defectList/toggleEditListAttributes'
);

export const modifyListAttribute = createAction(
  'defectList/modifyListAttribute'
);

export const openAddNewDefectsDialog = createAction(
  'defectList/openAddNewDefectsDialog'
);

export const closeAddNewDefectsDialog = createAction(
  'defectList/closeAddNewDefectsDialog'
);

export const openConfirmDefectItemDeleteDialog = createAction(
  'defectList/openConfirmDefectItemDeleteDialog'
);

export const closeConfirmDefectItemDeleteDialog = createAction(
  'defectList/closeConfirmDefectItemDeleteDialog'
);

export const openEditDefectItemDialog = createAction(
  'defectList/openEditDefectItemDialog'
);

export const closeEditDefectItemDialog = createAction(
  'defectList/closeEditDefectItemDialog'
);

export const setDefectImageToAdd = createAction(
  'defectList/setDefectImageToAdd'
);

export const setDefectImageToDelete = createAction(
  'defectList/setDefectImageToDelete'
);

export const openDefectItemFixDialog = createAction(
  'defectList/openDefectItemFixDialog'
);
export const openDefectAssigneeSwitchDialog = createAction(
  'defectList/openDefectAssigneeSwitchDialog'
);

export const closeDefectAssigneeSwitchDialog = createAction(
  'defectList/closeDefectAssigneeSwitchDialog'
);

export const closeDefectItemFixDialog = createAction(
  'defectList/closeDefectItemFixDialog'
);

export const openDefectAdditionDialog = createAction(
  'defectList/openDefectAdditionDialog'
);
export const closeDefectAdditionDialog = createAction(
  'defectList/closeDefectAdditionDialog'
);

export const setIsCreatingOrUpdatingDefectData = createAction(
  'defectList/setIsCreatingOrUpdatingDefectData'
);

export const setImageDescription = createAction(
  'defectList/setImageDescription'
);

export const removeImageDescription = createAction(
  'defectList/removeImageDescription'
);
