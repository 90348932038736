import { createAction } from '@reduxjs/toolkit';

export const openBulletinDialog = createAction(
  'bulletinBoard/bulletins/openBulletinDialog'
);
export const closeBulletinDialog = createAction(
  'bulletinBoard/bulletins/closeBulletinDialog'
);

export const openCreateBulletin = createAction(
  'bulletinBoard/bulletins/openCreateBulletin'
);

export const openJoiningInstructionDialog = createAction(
  'bulletinBoard/bulletins/openJoiningInstructionDialog'
);

export const closeJoiningInstructionDialog = createAction(
  'bulletinBoard/bulletins/closeJoiningInstructionDialog'
);

export const closeCreateBulletin = createAction(
  'bulletinBoard/bulletins/closeCreateBulletin'
);

export const openImageSelector = createAction(
  'bulletinBoard/bulletins/openImageSelector'
);

export const closeImageSelector = createAction(
  'bulletinBoard/bulletins/closeImageSelector'
);

export const resetBulletinData = createAction(
  'bulletinBoard/bulletins/resetBulletinData'
);

export const openEditBulletin = createAction(
  'bulletinBoard/bulletins/openEditBulletin'
);

export const closeEditBulletin = createAction(
  'bulletinBoard/bulletins/closeEditBulletin'
);

export const openDeleteBulletinDialog = createAction(
  'bulletinBoard/bulletins/openDeleteBulletinDialog'
);

export const closeDeleteBulletinDialog = createAction(
  'bulletinBoard/bulletins/closeDeleteBulletinDialog'
);

export const openSendBulletin = createAction(
  'bulletinBoard/bulletins/openSendBulletin'
);

export const closeSendBulletin = createAction(
  'bulletinBoard/bulletins/closeSendBulletin'
);

export const addFilepermissionUrl = createAction(
  'bulletinBoard/bulletins/addFilepermissionUrl'
);

export const resetFilepermissionUrls = createAction(
  'bulletinBoard/bulletins/resetFilepermissionUrls'
);

export const setLoadingPdf = createAction(
  'bulletinBoard/bulletins/setLoadingPdf'
);

export const openAddEmailDialog = createAction(
  'bulletinBoard/bulletins/openAddEmailDialog'
);

export const closeAddEmailDialog = createAction(
  'bulletinBoard/bulletins/closeAddEmailDialog'
);

export const setBulletinImageUrls = createAction(
  'bulletinBoard/bulletins/setBulletinImageUrls'
);

export const setLastOpenedBulletinId = createAction(
  'bulletinBoard/bulletins/setLastOpenedBulletinId'
);

export const setOpenAttachmentDeleteDialog = createAction(
  'bulletinBoard/bulletins/setOpenAttachmentDeleteDialog'
);

export const addAttachments = createAction(
  'bulletinBoard/bulletins/addAttachments'
);