import { createAsyncThunk } from '@reduxjs/toolkit';
import { selectSelectedDefectListId } from '../selectors/defect-list.selectors';
import { getDefectListChangeHistory } from 'scenes/DefectList/services/get-defect-list-change-history.service';
import { selectSelectedProjectId } from 'store/projects/selectors/projectsSelectors';

export const loadDefectListChangeHistory = createAsyncThunk(
  'defectLists/loadDefectListChangeHistory',
  async (_, { getState }) => {
    const selectedProjectId = selectSelectedProjectId(getState());
    const selectedDefectListId = selectSelectedDefectListId(getState());

    const changeHistoryItems = await getDefectListChangeHistory(
      selectedProjectId,
      selectedDefectListId
    );

    return changeHistoryItems;
  }
);
