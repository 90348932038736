import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import AppSyncClient from 'lixani-lib-graphql';
import dayjs from 'settings/dayjs';
import i18n from './i18n';
import moment from 'moment';
import storage from 'redux-persist/lib/storage';
import * as Sentry from '@sentry/browser';
import { combineReducers } from 'redux';
import { I18nextProvider } from 'react-i18next';
import {
  persistReducer,
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  createMigrate,
} from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider, useSelector } from 'react-redux';
import { transitions, positions, Provider as AlertProvider } from 'react-alert';
import { useDispatch } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import AlertTemplate from './AlertTemplate';
import './index.css';
import Config from './Config';
import App from './scenes/App';
import ErrorBoundary from './ErrorBoundary';
import rootReducer from './store/reducer';
import { configureStore } from '@reduxjs/toolkit';
import workCalendarReducer from './scenes/WorkDemo/workCalendarSlice';
import workSearchParamsReducer from './scenes/WorkDemo/workSearchParamsSlice';
import bulletinBoardReducer from './scenes/BulletinBoard/BulletinBoardSlice';

import projectFormsReducer from './scenes/ProjectForms/ProjectFormSlice';
import defectListReducer from './scenes/DefectList/store/DefectListSlice';
import { permissionSlice } from './scenes/Permissions/store/slices/permissionsSlice';
import resourcesReducer from 'store/resources/resourcesSlice';
import projectsReducer from 'store/projects/projectsSlice';

import mutationQueryResolver from './LixaniAPI/mutationQueryResolver';

import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { useTheme } from './components/theme';

import * as serviceWorkerRegistration from './registerServiceWorker';
import { projectPermissionsSlice } from 'scenes/Permissions/store/slices/projectPermissionsSlice';
import { permissionsDialogSlice } from 'scenes/Permissions/store/slices/permissionsDialogSlice';
import { projectGallerySlice } from 'scenes/ProjectGallery/store/slices/projectGallerySlice';
import { recordsSlice } from 'store/records/recordsSlice';
import { settingsSlice } from 'scenes/Settings/store/slices/settingsSlice';
import { invoicesSlice } from 'store/invoices/invoicesSlice';
import { orientationSlice } from 'store/orientation/orientationSlice';
import interactionsSliceReducer from 'store/interactions/interactionsSlice';

import { assignmentsSlice } from 'store/assignments/assignmentsSlice';
import { notificationSnackbarSlice } from 'store/notificationSnackbar/notificationSnackbar.slice';
import breadcrumbsSliceReducer from 'store/breadcrumbs/breadcrumbsSlice';
import { loadUserSettings } from 'store/user/thunks/loadUserSettings.thunk';
import {
  selectUserLoaded,
  selectUserSettingsLoaded,
} from 'store/user/selectors.js/user.selectors';
import userSlice from 'store/user/userSlice';
import { loadUser } from 'store/user/thunks/loadUser.thunk';
import { projectJoinSlice } from 'store/projects/slices/projectJoinSlice';
import projectUsersSlice from 'store/projectUsers/projectUsersSlice';
import { reportsSlice } from 'store/reports/reportsSlice';
import { expenseTrackingSlice } from 'scenes/ExpenseTracking/store/expenseTrackingSlice';
import filepermissionReactionUsersSlice from 'store/filepermissionReactionUsers/filepermissionReactionUsersSlice';
import { filepermissionsSlice } from 'store/filepermissions/filepermissionsSlice';
import { projectBankSlice } from 'scenes/ProjectBank/store/slices/projectBank.slice';
import { translationsSlice } from 'store/translations/translationsSlice';
import { userProfileSlice } from 'scenes/UserProfile/store/userProfileSlice';
import { approvalsSlice } from 'store/approvals/approvalsSlice';
import { timelineSlice } from 'scenes/Timelines/store/timelineSlice';
import { manualContactsSlice } from 'store/manualContacts/manualContacts.slice';
import { imageSelectorSlice } from 'components/ImageSelectorDialog/store/imageSelectorSlice';
import { SnackbarProvider } from 'notistack';
import { employerAllowancesSlice } from 'store/employerAllowances/employerAllowancesSlice';
import { projectResourcesSlice } from 'scenes/ProjectResources/store/projectResourcesSlice';
import recordAllowancesSlice from 'store/recordAllowances/recordAllowancesSlice';
import cardsSlice from 'store/cards/cardsSlice';
import customerSlice from 'store/customer/customerSlice';
import notificationsSlice from 'store/notifications/notificationsSlice';
import filingsSlice from 'scenes/Filings/FilingsSlice';

console.log('----- config -----');
console.log(Config);
console.log('------------------');

if (Config.SENTRY_DSN) {
  Sentry.init({
    dsn: Config.SENTRY_DSN,
    release: Config.BUILD_TS,
    integrations: function (integrations) {
      return integrations.filter(function (integration) {
        if (!process.env.production) {
          // Disables breadcrumbs unless in production mode
          return integration.name !== 'Breadcrumbs';
        }
      });
    },
  });
}

const graphqlErrorHandler = (graphQLErrors) => {
  console.log(graphQLErrors);
  for (let error of graphQLErrors) {
    const { message, locations, path } = error;
    Sentry.addBreadcrumb({
      category: 'graphql',
      message: `GraphQL error: ${message}`,
      data: {
        locations: JSON.stringify(locations),
        path: JSON.stringify(path),
      },
      level: 'error',
    });
  }
  Sentry.captureException(new Error('GraphQL error'));
};

// todo: Configure sentry user
//Sentry.configureScope((scope) => {
//  scope.setUser({"id": "123-123"});
//  scope.setUser({"email": "john.doe@example.com"});
//});

const migrations = {
  [Config.BUILD_TS]: (state) => {
    // initial state will be returned.
    return undefined;
  },
};

const rootPersistConfig = {
  key: 'root',
  storage: storage,
  whitelist: [''],
  version: Config.BUILD_TS,
  migrate: createMigrate(migrations, { debug: false }),
};

const projectUsersPersisConfig = {
  key: 'projectUsers',
  storage: storage,
  whitelist: ['users', 'userPersonalResources'],
  version: Config.BUILD_TS,
  migrate: createMigrate(migrations, { debug: false }),
};

const projectsPersistConfig = {
  key: 'projects',
  storage: storage,
  whitelist: ['projects'],
  version: Config.BUILD_TS,
  migrate: createMigrate(migrations, { debug: false }),
};

const resourcesPersistConfig = {
  key: 'resources',
  storage: storage,
  whitelist: ['loggedInUserResources'],
  version: Config.BUILD_TS,
  migrate: createMigrate(migrations, { debug: false }),
};

// const workCalendarPersistConfig = {
//   key: 'workCalendar',
//   storage: storage,
//   whitelist: ['projectsData'],
//   version: Config.BUILD_TS,
//   migrate: createMigrate(migrations, { debug: false }),
// };

const appReducer = combineReducers({
  root: rootReducer,
  workCalendar: workCalendarReducer,
  workSearchParams_demo: workSearchParamsReducer,
  bulletinBoard: bulletinBoardReducer,
  projectForms: projectFormsReducer,
  defectList: defectListReducer,
  permissions: permissionSlice.reducer,
  projectPermissions: projectPermissionsSlice.reducer,
  permissionsDialog: permissionsDialogSlice.reducer,
  resources: persistReducer(resourcesPersistConfig, resourcesReducer),
  expenseTracking: expenseTrackingSlice.reducer,
  projects: persistReducer(projectsPersistConfig, projectsReducer),
  projectJoin: projectJoinSlice.reducer,
  projectGallery: projectGallerySlice.reducer,
  assignments: assignmentsSlice.reducer,
  invoices: invoicesSlice.reducer,
  orientation: orientationSlice.reducer,
  records: recordsSlice.reducer,
  settings: settingsSlice.reducer,
  notificationSnackbar: notificationSnackbarSlice.reducer,
  breadcrumbs: breadcrumbsSliceReducer,
  user: userSlice,
  projectUsers: persistReducer(projectUsersPersisConfig, projectUsersSlice),
  reports: reportsSlice.reducer,
  filePermissions: filepermissionsSlice.reducer,
  filepermissionReactionUsers: filepermissionReactionUsersSlice,
  interactions: interactionsSliceReducer,
  projectBank: projectBankSlice.reducer,
  translations: translationsSlice.reducer,
  userProfile: userProfileSlice.reducer,
  approvals: approvalsSlice.reducer,
  timelines: timelineSlice.reducer,
  manualContacts: manualContactsSlice.reducer,
  imageSelector: imageSelectorSlice.reducer,
  employerAllowances: employerAllowancesSlice.reducer,
  projectResources: projectResourcesSlice.reducer,
  recordAllowances: recordAllowancesSlice,
  cards: cardsSlice,
  customers: customerSlice,
  notifications: notificationsSlice,
  filings: filingsSlice,
});

const persistedReducer = persistReducer(rootPersistConfig, appReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

const persistor = persistStore(store);

/**
 * initialize language and theme
 * note: below component is vital for newest i18next to work
 * it'll crash without this because i18next backend promise
 * has some serious issues and will crash before getting lang files
 * wait for i18n init before showing rest of the app
 * newest i18next-react added hooks which are quite useful and worth this
 */
function AppInit() {
  const [isLanguageInit, setIsLanguageInit] = useState(false);
  const dispatch = useDispatch();

  const isUserSettingsLoaded = useSelector(selectUserSettingsLoaded);
  const isUserLoaded = useSelector(selectUserLoaded);

  const userSetting = useSelector((state) => state.root.userSetting);

  const theme =
    userSetting && userSetting.theme ? userSetting.theme : 'classic';

  const providerTheme = useTheme(theme);

  useEffect(() => {
    dispatch(loadUser()).then((res) => {
      if (res.error) {
        setTimeout(() => {
          window.location.reload();
        }, 10000);
      }
    });

    i18n.on('initialized', () => {
      dispatch(loadUserSettings());

      setIsLanguageInit(true);
    });
  }, []);

  return isUserSettingsLoaded && isLanguageInit && isUserLoaded ? (
    <MuiThemeProvider theme={providerTheme}>
      <CssBaseline />
      <Router>
        <App />
      </Router>
    </MuiThemeProvider>
  ) : null;
}

// optional configuration for react-alert
const options = {
  // you can also just use 'bottom center'
  position: positions.TOP_CENTER,
  timeout: 0,
  offset: '30px',
  // you can also just use 'scale'
  transition: transitions.SCALE,
  type: 'error',
};

const renderApp = () =>
  ReactDOM.render(
    <ErrorBoundary>
      <AppSyncClient
        graphqlEndpoint={Config.GRAPHQL_ENDPOINT}
        awsRegion={Config.AWS_REGION}
        awsAuthType={Config.AWS_AUTH_TYPE}
        ssoTokenUrl={Config.SSO_TOKEN_URL}
        ssoLoginUrl={Config.SSO_LOGIN_URL}
        filesDomain={Config.FILES_DOMAIN}
        build={Config.BUILD_TS}
        mutationQueryResolver={mutationQueryResolver}
        graphqlErrorHandler={graphqlErrorHandler}
        loading={<></>}
      >
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <I18nextProvider i18n={i18n}>
              <AlertProvider template={AlertTemplate} {...options}>
                <SnackbarProvider maxSnack={5}>
                  <AppInit />
                </SnackbarProvider>
              </AlertProvider>
            </I18nextProvider>
          </PersistGate>
        </Provider>
      </AppSyncClient>
    </ErrorBoundary>,
    document.getElementById('root')
  );

const swOnInstall = () => {
  console.log('serviceworker update detected');
  // give 2 seconds to app to init, because listening in app.js
  setTimeout(() => window.postMessage({ type: 'swInstall' }), '2000');
};

serviceWorkerRegistration.register({ onInstall: swOnInstall });

if (localStorage.getItem('lixaniLibGraphqlBuild') === Config.BUILD_TS) {
  renderApp();
} else {
  renderApp();
}
