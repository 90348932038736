import { createEntityAdapter, createSlice, isAnyOf } from '@reduxjs/toolkit';
import {
  setMobileShowList,
  setSelectedFolder,
  setSelectedProjectId,
  toggleShowAll,
} from 'scenes/Project/store/actions/projectActions';
import { loadProcountorDimensions } from 'scenes/Project/store/thunks/loadProcountorDimensions.thunk';
import { loadProjectByProjectKey } from 'scenes/Project/store/thunks/loadProjectByProjectKey.thunk';
import { loadProjectByProjectKeyAfterJoining } from 'scenes/Project/store/thunks/loadProjectByProjectKeyAfterJoining';
import { saveProcountorDimension } from 'scenes/Project/store/thunks/saveProcountorDimension.thunk';
import { loadProjectRecordTotalHours } from 'store/records/thunks/loadProjectRecordTotalHours';
import { loadProjectRecordsFromToday } from 'store/records/thunks/loadProjectRecordsFromToday.thunk';
import { loadResourcesAndResourceEmployersOfProject } from 'store/resources/thunks/loadResourcesAndResourceEmployersOfProject';
import { loadResourcesOfProjectWithEmployerData } from 'store/resources/thunks/loadResourcesOfProjectWithEmployerData';
import { createOrUpdateUser } from 'store/user/thunks/createOrUpdateUser.thunk';
import { setSearchFilter } from './actions/projectSearch.actions';
import { addEmployerForUser } from './thunks/addEmployerForUser.thunk';
import { addProjectToMergeFrom } from './thunks/addProjectToMergeFrom.thunk';
import { createProject } from './thunks/createProject.thunk';
import { getProjectMergeStatus } from './thunks/getProjectMergeStatus.thunk';
import { loadLatestActiveProjectsOfUser } from './thunks/loadLatestActiveProjectsOfUser.thunk';
import { loadLatestProjectsClockedInTo } from './thunks/loadLatestProjectsLoadedIn.thunk';
import {
  loadUserProjects,
  loadUserProjectsAndResources,
} from './thunks/project.thunks';
import { putProjectToMergeInto } from './thunks/putProjectToMergeInto.thunk';
import { removeProjectToMergeFrom } from './thunks/removeProjectToMergeFrom.thunk';
import { removeProjectToMergeInto } from './thunks/removeProjectToMergeInto.thunk';
import { startProjectMergeFrom } from './thunks/startProjectMergeFrom.thunk';
import { startProjectMergeInto } from './thunks/startProjectMergeInto.thunk';
import { updateProjectFromIlve } from './thunks/updateProjectFromIlve.thunk';
import { updateProjectIdCard } from './thunks/updateProjectIdCard.thunk';
import { userJoinProject } from './thunks/userJoinProject.thunk';
import { updateProject } from './thunks/updateProject.thunk';
import { updateProjectDatesFromFilings } from './thunks/updateProjectDatesFromFilings.thunk';
import { deleteProject } from './thunks/deleteProject.thunk';
import { loadResourcesDataOfProjectSpecificFeature } from 'store/resources/thunks/loadResourcesDataOfProjectSpecificFeature';
import { loadBusinessIntegrationPartners } from './thunks/loadBusinessIntegrationPartners.thunk';

const projectsAdapter = createEntityAdapter();

const projectEmployeeEmployerProjectsAdapter = createEntityAdapter();

const projectsSlice = createSlice({
  name: 'projects',
  initialState: {
    projects: projectsAdapter.getInitialState(),
    loading: false,
    selectedProjectId: '',
    userProjectsLoaded: false,
    projectRecordHours: {},
    projectRecordHoursLoading: false,
    latestClockedInProjectIds: [],
    latestUserActiveProjectIds: [],
    lastProjectClockedInTo: null,
    folder: '',
    showAll: false,
    mobileShowList: false,
    procountorDimensions: [],
    savingProcountorDimension: false,
    isAddingEmployer: false,
    searchFilter: '',
    isProjectJoined: false,
    projectEmployeeEmployerProjects:
      projectEmployeeEmployerProjectsAdapter.getInitialState(),
    projectMergeStatus: { mergeIntoStatus: undefined, mergeFromStatus: [] },
    projectsMerging: false,
    addingProjectToMerge: false,
    isProjectCreateLoading: false,
    businessIntegrationPartners: {},
  },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(setMobileShowList, (state, action) => {
        state.mobileShowList = action.payload;
      })
      .addCase(setSelectedProjectId, (state, action) => {
        state.selectedProjectId = action.payload;
      })
      .addCase(setSelectedFolder, (state, action) => {
        state.folder = action.payload;
      })
      .addCase(toggleShowAll, (state, action) => {
        state.showAll = !state.showAll;
      })
      .addCase(loadUserProjects.pending, (state) => {
        state.loading = true;
      })
      .addCase(loadUserProjects.fulfilled, (state, action) => {
        const userProjects = action.payload.filter(
          (project) => project && project.id
        );

        state.projects = projectsAdapter.setMany(state.projects, userProjects);
        state.loading = false;
        state.userProjectsLoaded = true;
      })
      .addCase(loadUserProjects.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      })
      .addCase(loadUserProjectsAndResources.pending, (state) => {
        state.loading = true;
      })
      .addCase(loadUserProjectsAndResources.fulfilled, (state, action) => {
        state.projects = projectsAdapter.setMany(
          state.projects,
          action.payload.projects
        );
        state.projectToRedirectTo = action.payload.projectToRedirectTo;
        state.loading = false;
        state.userProjectsLoaded = true;
      })
      .addCase(loadUserProjectsAndResources.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      })
      .addCase(loadProjectByProjectKey.fulfilled, (state, action) => {
        state.projects = projectsAdapter.setOne(state.projects, action.payload);
      })
      .addCase(
        loadProjectByProjectKeyAfterJoining.fulfilled,
        (state, action) => {
          state.projects = projectsAdapter.setOne(
            state.projects,
            action.payload
          );
        }
      )
      .addCase(addEmployerForUser.pending, (state, action) => {
        state.isAddingEmployer = true;
      })
      .addCase(addEmployerForUser.fulfilled, (state, action) => {
        state.projects = projectsAdapter.setOne(
          state.projects,
          action.payload.newEmployerProject
        );
        state.isAddingEmployer = false;
      })
      .addCase(userJoinProject.fulfilled, (state, action) => {
        state.projects = projectsAdapter.setMany(
          state.projects,
          action.payload.projects
        );
      })
      .addCase(addEmployerForUser.rejected, (state, action) => {
        state.isAddingEmployer = false;
      })
      .addCase(loadLatestProjectsClockedInTo.fulfilled, (state, action) => {
        state.latestClockedInProjectIds = action.payload;
      })
      .addCase(loadLatestActiveProjectsOfUser.fulfilled, (state, action) => {
        state.latestUserActiveProjectIds = action.payload;
      })
      .addCase(loadProjectRecordsFromToday.fulfilled, (state, action) => {
        state.projectEmployeeEmployerProjects =
          projectEmployeeEmployerProjectsAdapter.setMany(
            state.projectEmployeeEmployerProjects,
            action.payload.employerProjectsOfProjectResources
          );
      })
      .addCase(createProject.pending, (state, action) => {
        state.isProjectCreateLoading = true;
      })
      .addCase(createProject.fulfilled, (state, action) => {
        projectsAdapter.setOne(state.projects, action.payload.newProject);
        state.isProjectCreateLoading = false;
      })

      .addCase(createProject.rejected, (state, action) => {
        state.isProjectCreateLoading = false;
      })
      .addCase(updateProject.pending, (state) => {
        state.error = null;
      })
      .addCase(updateProject.fulfilled, (state, action) => {
        state.projects = projectsAdapter.updateOne(state.projects, {
          id: action.payload.id,
          changes: action.payload,
        });
      })
      .addCase(updateProject.rejected, (state, action) => {
        state.error = action.error;
      })
      .addCase(deleteProject.pending, (state) => {
        state.error = null;
      })
      .addCase(deleteProject.fulfilled, (state, action) => {
        state.projects = projectsAdapter.updateOne(state.projects, {
          id: action.payload.updatedProject.id,
          changes: action.payload.updatedProject,
        });
      })
      .addCase(deleteProject.rejected, (state, action) => {
        state.error = action.error;
      })
      .addCase(updateProjectDatesFromFilings.pending, (state) => {
        state.error = null;
      })
      .addCase(updateProjectDatesFromFilings.fulfilled, (state, action) => {
        state.projects = projectsAdapter.updateOne(state.projects, {
          id: action.payload.id,
          changes: action.payload,
        });
      })
      .addCase(updateProjectDatesFromFilings.rejected, (state, action) => {
        state.error = action.error;
      })
      .addCase(createOrUpdateUser.fulfilled, (state, action) => {
        if (action.payload.employer) {
          projectsAdapter.setOne(state.projects, action.payload.employer);
        }
      })
      .addCase(updateProjectIdCard.fulfilled, (state, action) => {
        state.projects = projectsAdapter.upsertOne(
          state.projects,
          action.payload
        );
      })

      .addCase(updateProjectFromIlve.pending, (state) => {
        state.error = null;
      })
      .addCase(updateProjectFromIlve.fulfilled, (state, action) => {
        state.projects = projectsAdapter.upsertOne(
          state.projects,
          action.payload.project
        );
      })
      .addCase(updateProjectFromIlve.rejected, (state, action) => {
        state.error = action.error;
      })

      .addCase(loadProjectRecordTotalHours.pending, (state) => {
        state.projectRecordHoursLoading = true;
      })
      .addCase(loadProjectRecordTotalHours.fulfilled, (state, action) => {
        state.projectRecordHours[action.payload.id] =
          action.payload.recordHourData;
        state.projectRecordHoursLoading = false;
      })
      .addCase(loadProjectRecordTotalHours.rejected, (state) => {
        state.projectRecordHoursLoading = false;
      })
      .addCase(loadProcountorDimensions.fulfilled, (state, action) => {
        state.procountorDimensions = action.payload.procountorDimensions;
      })
      .addCase(saveProcountorDimension.pending, (state) => {
        state.savingProcountorDimension = true;
      })
      .addCase(saveProcountorDimension.fulfilled, (state, action) => {
        state.savingProcountorDimension = false;
        projectsAdapter.upsertOne(state.projects, {
          id: action.payload.projectId,
          procountorProjectDimensionId:
            action.payload.procountorProjectDimensionId,
        });
      })
      .addCase(saveProcountorDimension.rejected, (state, action) => {
        state.savingProcountorDimension = false;
      })
      .addCase(setSearchFilter, (state, action) => {
        state.searchFilter = action.payload;
      })
      .addCase(
        loadResourcesAndResourceEmployersOfProject.fulfilled,
        (state, action) => {
          if (action.payload.employers) {
            const employers = action.payload.employers.filter(
              (employer) => employer && employer.id
            );
            state.projects = projectsAdapter.setMany(state.projects, employers);
          }
        }
      )
      .addCase(putProjectToMergeInto.fulfilled, (state, action) => {
        projectsAdapter.setOne(state.projects, action.payload.updatedProject);
        state.projectMergeStatus.mergeIntoStatus =
          action.payload.mergeIntoStatus;
      })
      .addCase(addProjectToMergeFrom.fulfilled, (state, action) => {
        projectsAdapter.setOne(state.projects, action.payload.updatedProject);
        state.projectMergeStatus.mergeFromStatus =
          state.projectMergeStatus.mergeFromStatus.filter(
            (status) =>
              status.projectKey !== action.payload.mergeFromStatus.projectKey
          );
        state.projectMergeStatus.mergeFromStatus.push(
          action.payload.mergeFromStatus
        );
      })
      .addCase(getProjectMergeStatus.fulfilled, (state, action) => {
        state.projectMergeStatus = action.payload;
      })
      .addCase(removeProjectToMergeFrom.fulfilled, (state, action) => {
        state.projectMergeStatus.mergeFromStatus =
          state.projectMergeStatus.mergeFromStatus.filter(
            (status) => status.projectKey !== action.payload.removedKey
          );
      })
      .addCase(startProjectMergeFrom.fulfilled, (state, action) => {
        state.projectMergeStatus.mergeFromStatus =
          state.projectMergeStatus.mergeFromStatus.filter(
            (status) => status.projectKey !== action.meta.arg
          );
      })
      .addCase(
        loadResourcesOfProjectWithEmployerData.fulfilled,
        (state, action) => {
          projectsAdapter.setMany(
            state.projects,
            action.payload.employerProjects
          );
        }
      )
      .addCase(
        loadResourcesDataOfProjectSpecificFeature.fulfilled,
        (state, action) => {
          projectsAdapter.setMany(
            state.projects,
            action.payload.employerProjects
          );
        }
      )
      .addCase(loadBusinessIntegrationPartners.fulfilled, (state, action) => {
        if (action.payload) {
          const { businessId, integrationPartner } = action.payload;

          state.businessIntegrationPartners = {
            ...state.businessIntegrationPartners,
            [businessId]: integrationPartner,
          };
        }
      })
      .addMatcher(
        isAnyOf(startProjectMergeFrom.pending, startProjectMergeInto.pending),
        (state, action) => {
          state.projectsMerging = true;
        }
      )
      .addMatcher(
        isAnyOf(
          startProjectMergeFrom.fulfilled,
          startProjectMergeFrom.rejected,
          startProjectMergeInto.fulfilled,
          startProjectMergeInto.rejected
        ),
        (state, action) => {
          state.projectsMerging = false;
        }
      )

      .addMatcher(
        isAnyOf(
          removeProjectToMergeInto.fulfilled,
          startProjectMergeInto.fulfilled
        ),
        (state, action) => {
          state.projectMergeStatus.mergeIntoStatus = undefined;
        }
      )
      .addMatcher(
        isAnyOf(addProjectToMergeFrom.pending, putProjectToMergeInto.pending),
        (state, action) => {
          state.addingProjectToMerge = true;
        }
      )
      .addMatcher(
        isAnyOf(
          addProjectToMergeFrom.fulfilled,
          addProjectToMergeFrom.rejected,
          putProjectToMergeInto.fulfilled,
          putProjectToMergeInto.rejected
        ),
        (state, action) => {
          state.addingProjectToMerge = false;
        }
      );
  },
});

export default projectsSlice.reducer;
